import { ReactComponent as NodataIcon } from "@assets/icons/empty-error.svg";
import { COLORS, TYPO } from "@styles/index";

import { styled } from "styled-components";

interface EmptyPlaceholderProps {
  title?: string;
  description: string;
  descriptionStyle?: React.CSSProperties;
}

export const DataEmptyPlaceholder = ({
  title,
  description,
  descriptionStyle,
}: EmptyPlaceholderProps) => {
  return (
    <DataEmptyContainer>
      <NodataIcon />
      <div>
        {title && <p>{title}</p>}
        <small style={descriptionStyle}>{description}</small>
      </div>
    </DataEmptyContainer>
  );
};

const DataEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 76vh;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    p {
      ${TYPO.HEADING_8};
      color: ${COLORS.GRAY2};
    }

    small {
      ${TYPO.BODY_8};
      font-weight: 400;
      color: ${COLORS.GRAY6};
    }
  }
`;
