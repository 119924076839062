import { ResponseStationSearch } from "@apis/map/types";
import { StationDetail } from "@pages/Map";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IChargerStationStoreProps {
  globalChargerStation: ResponseStationSearch | null;
  globalChargerStationList: StationDetail[];
  setGlobalChargerStation: (
    globalChargerStation: ResponseStationSearch | null,
  ) => void;
  setGlobalChargerStationList: (
    globalChargerStationList: StationDetail[],
  ) => void;
}

const initialData = {
  globalChargerStation: null,
  globalChargerStationList: [],
};

const useChargerStationStore = create(
  persist<IChargerStationStoreProps>(
    (set) => ({
      ...initialData,
      setGlobalChargerStation: (
        globalChargerStation: ResponseStationSearch | null,
      ) => {
        set({ globalChargerStation });
      },
      setGlobalChargerStationList: (
        globalChargerStationList: StationDetail[],
      ) => {
        set({ globalChargerStationList });
      },
    }),
    {
      name: "chargerStation",
    },
  ),
);

export default useChargerStationStore;
