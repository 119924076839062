import { ResponseStationSearch } from "@apis/map/types";

/** local */
export const getLocalData = (key: string) => {
  return localStorage.getItem(key);
};

export const setLocalData = (key: string, data: string) => {
  localStorage.setItem(key, data);
};

export const removeLocalData = (key: string) => {
  return localStorage.removeItem(key);
};

/** session */
export const getSessionData = (key: string) => {
  return sessionStorage.getItem(key);
};

export const setSessionData = (key: string, data: string) => {
  sessionStorage.setItem(key, data);
};

export const removeSessionData = (key: string) => {
  return sessionStorage.removeItem(key);
};

/** recent search */
export const setLocalRecentSearchValue = (data: ResponseStationSearch) => {
  const recentSearch = getLocalData("recentSearch");
  if (recentSearch) {
    const recentSearchList = JSON.parse(recentSearch);
    const isExist = recentSearchList.find(
      (item: ResponseStationSearch) => item.stationId === data.stationId,
    );

    if (!isExist) {
      recentSearchList.push(data);
      setLocalData("recentSearch", JSON.stringify(recentSearchList));
    }
  } else {
    setLocalData("recentSearch", JSON.stringify([data]));
  }
};

export const getLocalRecentSearchValue = () => {
  const recentSearch = getLocalData("recentSearch");
  return recentSearch ? JSON.parse(recentSearch) : [];
};

export const removeLocalRecentSearchValue = (stationId: string) => {
  const recentSearch = getLocalData("recentSearch");
  if (recentSearch) {
    const recentSearchList = JSON.parse(recentSearch);
    const newRecentSearchList = recentSearchList.filter(
      (item: ResponseStationSearch) => item.stationId !== stationId,
    );

    setLocalData("recentSearch", JSON.stringify(newRecentSearchList));
  }
};

export const removeAllLocalRecentSearchValue = () => {
  removeLocalData("recentSearch");
};
