import { StationDetail } from "@pages/Map";
import { ChargerBasicInfoCard } from "@pages/SearchAddress";
import { COLORS, TYPO } from "@styles/index";
import { css, styled } from "styled-components";
import { ChargerState } from "../ChargerState";

interface ChargerCardProps {
  station: StationDetail;
  onClick?: () => void;
}

export const ChargerCard = ({ station, onClick }: ChargerCardProps) => {
  const {
    stationName,
    address,
    addressDetail,
    isAllDayOpen,
    isFreeEntry,
    stationBrand,
    status,
    distance,
    usableFastChargerCount,
    usableLateChargerCount,
    lateChargerTotalCount,
    fastChargerTotalCount,
  } = station;

  return (
    <>
      <ChargerCardArea onClick={onClick}>
        <ChargerContentArea>
          <ChargerBasicInfoCard
            hasBrand
            disabledContainerPadding
            stationName={stationName}
            stationAddress={address}
            stationAddressDetail={addressDetail}
            stationBrand={stationBrand}
            distance={distance}
          />

          <ChargerInfoLabelArea>
            {/* 특이사항 컨텐츠  ex ) 외부인 사용불가, 24시간 사용가능 */}
            {stationBrand === "EV" ? (
              <InfoLabel $brand_check={"EV"}>에버온</InfoLabel>
            ) : (
              <InfoLabel>로밍</InfoLabel>
            )}
            {!isFreeEntry && <InfoLabel>외부인사용불가</InfoLabel>}
            {isAllDayOpen && <InfoLabel>24시간 사용가능</InfoLabel>}
          </ChargerInfoLabelArea>
        </ChargerContentArea>

        {/* 충전기 타입 수량 */}
        <ChargerState
          status={status}
          usableFastChargerCount={usableFastChargerCount}
          usableLateChargerCount={usableLateChargerCount}
          lateChargerTotalCount={lateChargerTotalCount}
          fastChargerTotalCount={fastChargerTotalCount}
        />
      </ChargerCardArea>
    </>
  );
};

export const ChargerCardArea = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
`;

const ChargerContentArea = styled.div``;

const ChargerInfoLabelArea = styled.div`
  display: flex;
  gap: 4px;
`;

const InfoLabel = styled.span<{ $brand_check?: string }>`
  padding: 3px 6px;
  width: fit-content;
  vertical-align: middle;
  border-radius: 4px;
  border: 1px solid ${COLORS.GRAY9};
  ${TYPO.BODY_11};
  color: ${COLORS.GRAY6};
  height: 22px;
  background: ${COLORS.WHITE};

  ${({ $brand_check }) =>
    $brand_check === "EV" &&
    css`
      color: ${COLORS.PRIMARY};
      border-color: ${COLORS.PRIMARY};
    `}
`;
