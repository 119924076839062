import { COLORS, TYPO } from "@styles/index";
import { styled } from "styled-components";

interface InputProps {
  style?: React.CSSProperties;
  placeholder: string;
  value?: string;
  focused?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = (props: InputProps) => {
  const { style, placeholder, value, onChange, focused } = props;
  return (
    <StyledInput
      ref={(ref) => ref && focused && ref.focus()}
      style={style}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

const StyledInput = styled.input`
  border: none;
  width: 100%;
  ${TYPO.BODY_7};

  ::placeholder {
    color: ${COLORS.GRAY6};
  }
`;
