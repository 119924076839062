import { useEffect, useState } from "react";

function easeOutExpo(t: number): number {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
}

export const useCountUp = (
  end: number,
  start = 0,
  duration = 2000,
  shouldCount = true,
) => {
  const [count, setCount] = useState(start);
  const frameRate = 1000 / 60;
  const totalFrame = Math.round(duration / frameRate);

  useEffect(() => {
    let currentNumber = start;
    let counter: NodeJS.Timer;

    if (shouldCount) {
      counter = setInterval(() => {
        const progress = easeOutExpo(++currentNumber / totalFrame);
        setCount(Math.round(end * progress));

        if (progress === 1) {
          clearInterval(counter);
        }
      }, frameRate);
    }

    return () => clearInterval(counter);
  }, [end, frameRate, start, totalFrame, shouldCount]);

  return count;
};
