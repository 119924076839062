import { ReactComponent as ChargeIcon } from "@assets/icons/charge.svg";
import { ReactComponent as ChargeGrayIcon } from "@assets/icons/charge-gray.svg";
import { ReactComponent as ChargeInspectionIcon } from "@assets/icons/danger.svg";
import { styled } from "styled-components";
import { COLORS, TYPO } from "@styles/index";

export type ChargerStateType = "canUse" | "inUse" | "maintenance";

interface ChargerStateProps {
  status?: ChargerStateType;
}
export const ChargerStatusLender = ({
  status = "canUse",
}: ChargerStateProps) => {
  const icon = () => {
    switch (status) {
      case "canUse":
        return <ChargeIcon />;
      case "inUse":
        return <ChargeGrayIcon />;
      case "maintenance":
        return <ChargeInspectionIcon />;

      default:
        return;
    }
  };

  const text = () => {
    switch (status) {
      case "canUse":
        return "사용가능";
      case "inUse":
        return "사용중";
      case "maintenance":
        return "충전기 상태 점검중";

      default:
        return;
    }
  };

  return (
    <ChargerStateContainer $isPossible={status}>
      {icon()}
      {text()}
    </ChargerStateContainer>
  );
};

const ChargerStateContainer = styled.span<{ $isPossible: ChargerStateType }>`
  display: flex;
  align-items: center;
  gap: 2px;
  ${TYPO.HEADING_8};

  color: ${({ $isPossible }) => {
    switch ($isPossible) {
      case "canUse":
        return COLORS.PRIMARY;

      case "inUse":
        return COLORS.GRAY4;

      case "maintenance":
        return COLORS.GRAY7;

      default:
        return;
    }
  }};
`;
