import { ReactComponent as Charge } from "@assets/icons/charge.svg";
import {
  ChargerStateType,
  ChargerStatusLender,
} from "@components/atoms/ChargerStatusLender";
import { COLORS, TYPO } from "@styles/index";
import { styled } from "styled-components";

interface ChargerStateProps {
  status: ChargerStateType;
  usableFastChargerCount: number;
  usableLateChargerCount: number;
  lateChargerTotalCount: number;
  fastChargerTotalCount: number;
}
export const ChargerState = ({
  status = "canUse",
  fastChargerTotalCount,
  lateChargerTotalCount,
  usableFastChargerCount,
  usableLateChargerCount,
}: ChargerStateProps) => {
  return (
    <ChargerCountArea $status={status}>
      <ChargerStatusLender status={status} />
      {/* 사용중 / 사용가능 */}
      {status !== "maintenance" && (
        <div>
          <span>
            완속
            <div className="countContainer">
              <Count $status={status}>{usableLateChargerCount}</Count>
              <span className="text">/{lateChargerTotalCount}</span>
            </div>
          </span>
          <span>
            급속
            <div className="countContainer">
              <Count $status={status}>{usableFastChargerCount}</Count>
              <span className="text">/{fastChargerTotalCount}</span>
            </div>
          </span>
        </div>
      )}
    </ChargerCountArea>
  );
};

const ChargerCountArea = styled.div<{ $status: ChargerStateType }>`
  background: ${({ $status }) => {
    if ($status === "canUse") return COLORS.PRIMARY7;
    if ($status === "inUse") return COLORS.GRAY11;
    if ($status === "maintenance") return COLORS.GRAY10;
  }};
  padding: 10px 20px;
  border-radius: 8px;
  ${TYPO.BODY_8};
  font-weight: 400;
  color: ${COLORS.GRAY6};
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    display: flex;
    gap: 4px;

    > span {
      display: flex;
      ${TYPO.HEADING_8};
      color: ${COLORS.GRAY4};
      gap: 4px;

      .countContainer {
        display: flex;
      }
      .text {
        ${TYPO.BODY_8};
        font-weight: 400;
      }
    }
  }
`;

const ChargerStateInfo = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${COLORS.PRIMARY};
`;

const Count = styled.span<{ $status: ChargerStateType }>`
  color: ${({ $status }) =>
    $status === "canUse" ? COLORS.PRIMARY : COLORS.GRAY4};
  ${TYPO.HEADING_8};
`;
