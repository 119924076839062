import { COLORS, TYPO } from "@styles/index";
import { PropsWithChildren } from "react";
import { css, styled } from "styled-components";

export type ButtonType = "fill" | "outline" | "text";

interface ButtonProps extends PropsWithChildren {
  onClick?: () => void;
  btnType: ButtonType;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  btnType,
  style,
  children,
  disabled,
  onClick,
}) => {
  return (
    <StyledButton
      $btnType={btnType}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ $btnType: ButtonType }>`
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  vertical-align: middle;
  transition: all 0.2s ease-in;
  ${TYPO.BUTTON_2B};
  ${({ $btnType }) => {
    switch ($btnType) {
      case "fill":
        return css`
          background: ${COLORS.PRIMARY};
          color: ${COLORS.WHITE};

          &:hover {
            background: ${COLORS.PRIMARY2};
          }

          &:active {
            background: ${COLORS.PRIMARY1};
          }

          &:disabled {
            background: ${COLORS.PRIMARY5};
          }
        `;
      case "outline":
        return css`
          background: ${COLORS.WHITE};
          color: ${COLORS.PRIMARY};
          border: 1px solid ${COLORS.PRIMARY};

          &:hover {
            color: ${COLORS.PRIMARY2};
            border: 1px solid ${COLORS.PRIMARY2};
          }

          &:active {
            color: ${COLORS.PRIMARY1};
            border: 1px solid ${COLORS.PRIMARY1};
          }

          &:disabled {
            color: ${COLORS.GRAY5};
            border: 1px solid ${COLORS.GRAY5};
          }
        `;

      case "text":
        return css`
          background: ${COLORS.WHITE};
          color: ${COLORS.GRAY7};
          ${TYPO.BODY_10};
          width: 100%;
          text-align: right;
          padding: 0;
          border-radius: unset;
        `;
    }
  }}
`;
