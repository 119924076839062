import { ICONS } from "@assets/icons";
import BottomSheet from "@components/atoms/BottomSheet";
import { COLORS, TYPO } from "@styles/index";
import { redirectNavigateApp } from "@utils/redirect-navigate-app.util";
import { styled } from "styled-components";

export interface RedirectLocationData {
  currentStationName: string;
  currentAddress: string;
  currentLatitude: number;
  currentLongitude: number;
  targetLatitude: number;
  targetLongitude: number;
}

interface RedirectNavigationBottomSheetProps {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  redirectLocationData?: RedirectLocationData;
  // TODO 주소를 받아 와야 될꺼 같음.
}
export const RedirectNavigationBottomSheet = ({
  isOpen,
  onClose,
  redirectLocationData,
}: RedirectNavigationBottomSheetProps) => {
  const navigationListData = [
    {
      icon: ICONS.MAP.TMAP,
      label: "티맵",
      handleNavigationClick: () => {
        if (!redirectLocationData) return;
        redirectNavigateApp({
          label: "티맵",
          locationData: redirectLocationData,
        });
        onClose(false);
      },
    },
    {
      icon: ICONS.MAP.KAKAO,
      label: "카카오맵",
      handleNavigationClick: () => {
        if (!redirectLocationData) return;
        redirectNavigateApp({
          label: "카카오맵",
          locationData: redirectLocationData,
        });
        onClose(false);
      },
    },
    {
      icon: ICONS.MAP.NAVER,
      label: "네이버지도",
      handleNavigationClick: () => {
        if (!redirectLocationData) return;
        redirectNavigateApp({
          label: "네이버지도",
          locationData: redirectLocationData,
        });
        onClose(false);
      },
    },
  ];

  return (
    <BottomSheet isOpen={isOpen} height="auto" disableLine onClose={onClose}>
      <NavigationContainer>
        <p className="navigationTitle">길 안내</p>
        <div>
          {navigationListData.map(({ icon, label, handleNavigationClick }) => (
            <NavigationItem key={label} onClick={handleNavigationClick}>
              <NavigationIcon src={icon} />
              <p>{label}</p>
            </NavigationItem>
          ))}
        </div>
      </NavigationContainer>
    </BottomSheet>
  );
};

const NavigationContainer = styled.div`
  padding-bottom: 16px;

  .navigationTitle {
    ${TYPO.HEADING_5};
    color: ${COLORS.GRAY2};
    padding: 24px 20px 16px;
  }
`;

const NavigationItem = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &:active {
    background: ${COLORS.GRAY11};
  }
`;

const NavigationIcon = styled.span<{ src: string }>`
  ${({ src }) => {
    return `
      background: url(${src}) no-repeat center center;
      background-size: contain;
      width: 24px;
      height: 24px;
    `;
  }}
`;
