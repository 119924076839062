import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export interface ErrorType {
  statusCode: number;
  message: string;
  error: string;
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data) {
      const { data } = error.response as AxiosResponse<ErrorType>;
      return Promise.reject(data);
    } else return Promise.reject(error);
  },
);

export interface RequestParams<RequestType> {
  url: string;
  method: "get" | "post" | "put" | "delete";
  requestBody?: RequestType;
  requestParams?: RequestType;
}

// network 모듈화
export const request = async <RequestType, ResponseType>({
  url,
  method,
  requestBody,
  requestParams,
}: RequestParams<RequestType>): Promise<AxiosResponse<ResponseType>> => {
  const config: AxiosRequestConfig = {
    url,
    method,
    data: requestBody,
    params: requestParams,
  };

  return axiosInstance(config);

  // const config: AxiosRequestConfig = {
  //   params: requestParams,
  // };

  // // return axiosInstance(config);
  // switch (method) {
  //   case "get":
  //     return axiosInstance.get(url, config);
  //   case "post":
  //     return axiosInstance.post(url, requestBody, config);
  //   case "put":
  //     return axiosInstance.put(url, requestBody, config);
  //   case "delete":
  //     return axiosInstance.delete(url, {
  //       data: requestBody,
  //     });
  //   default:
  //     return Promise.reject(new Error("Invalid HttpMethod"));
  // }
};
