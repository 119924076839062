import {
  StationBrandCode,
  requestChargerSearchMarker,
  requestSearchStation,
} from "@apis/map";
import { ResponseStationSearch } from "@apis/map/types";
import { ReactComponent as Arrow } from "@assets/icons/arrow-left.svg";
import { ReactComponent as Delete } from "@assets/icons/delete.svg";
import BrandLogoRender from "@components/atoms/BrandLogoRender";
import { Button } from "@components/atoms/Button";
import { DataEmptyPlaceholder } from "@components/atoms/DataEmptyPlaceholder";
import { EmptyPlaceholder } from "@components/atoms/EmptyPlaceholder";
import { Input } from "@components/atoms/Input";
import Lottie from "@components/atoms/Lottie";
import { HistoryItem } from "@components/molecules/HistoryItem";
import Layout from "@components/templates/Layout";
import useWindowSize from "@hooks/useWindowSize";
import { StationDetail } from "@pages/Map";
import useChargerStationStore from "@store/useChargerStationStore";
import useLocationStore from "@store/useLocationStore";
import { COLORS, TYPO } from "@styles/index";
import {
  getLocalRecentSearchValue,
  removeAllLocalRecentSearchValue,
  removeLocalRecentSearchValue,
  setLocalRecentSearchValue,
} from "@utils/storage.utils";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";

type SearchCategoryType = "충전소" | "주소";

const SearchAddress: React.FC = () => {
  const navigate = useNavigate();
  const { height } = useWindowSize();
  const categoryRef1 = React.useRef<HTMLDivElement>(null);
  const categoryRef2 = React.useRef<HTMLDivElement>(null);
  const [searchAddress, setSearchAddress] = useState<string>("");
  const [recentSearchValue, setRecentSearchValue] = useState<
    ResponseStationSearch[]
  >([]);

  const categoryWidth = [
    categoryRef1.current?.clientWidth,
    categoryRef2.current?.clientWidth,
  ];

  const { setGlobalChargerStationList } = useChargerStationStore(
    (store) => store,
  );

  const {
    setIsSearchChargerStation,
    globalCurrentLongitude,
    globalCurrentLatitude,
  } = useLocationStore((store) => store);

  const [selectedCategory, setCategory] =
    useState<SearchCategoryType>("충전소");

  const {
    data: searchData,
    isError,
    isLoading,
  } = useQuery(
    ["searchData", searchAddress, selectedCategory],
    () =>
      requestSearchStation({
        searchType: selectedCategory === "충전소" ? "stationName" : "address",
        keyword: searchAddress,
        curLatitude: globalCurrentLatitude || 37.565772,
        curLongitude: globalCurrentLongitude || 126.98872,
        page: 1,
        pageSize: 30,
      }),
    {
      retry: false,
      enabled: searchAddress.length > 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (!data) return;
        return data.rows;
      },
    },
  );

  const handleBackButtonClick = () => {
    navigate("/search");
  };

  const handleSearchAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    setSearchAddress(e.target.value);
  };

  const handleClearButtonClick = () => {
    setSearchAddress("");
  };

  const handleCategoryClick = (category: SearchCategoryType) => {
    setCategory(category);
  };

  useEffect(() => {
    // NOTE : 최근 검색어 로컬스토리지에서 가져오기
    setRecentSearchValue(getLocalRecentSearchValue());
  }, []);

  const { mutateAsync: fetchSearchStation } = useMutation(
    requestChargerSearchMarker,
  );

  const handleChargerBasicInfoCardItemClick = (item: ResponseStationSearch) => {
    if (!globalCurrentLatitude || !globalCurrentLongitude) return;
    setLocalRecentSearchValue(item);
    setRecentSearchValue((prev) => [...prev, item]);

    fetchSearchStation({
      latitude: item.latitude,
      longitude: item.longitude,
      curLatitude: globalCurrentLatitude,
      curLongitude: globalCurrentLongitude,
    }).then((res) => {
      const { distance, latitude, longitude, stations } = res[0];
      const stationData: StationDetail[] = stations.map((station) => {
        return { ...station, distance, latitude, longitude };
      });
      setGlobalChargerStationList(stationData);
      setIsSearchChargerStation(true);
      navigate("/");
    });
  };

  const handleHistoryItemClick = (item: ResponseStationSearch) => {
    if (!globalCurrentLatitude || !globalCurrentLongitude) {
      console.error("현재 위치를 가져올 수 없습니다.");
      return;
    }

    setLocalRecentSearchValue(item);
    fetchSearchStation({
      latitude: item.latitude,
      longitude: item.longitude,
      curLatitude: globalCurrentLatitude,
      curLongitude: globalCurrentLongitude,
    }).then((res) => {
      const { distance, latitude, longitude, stations } = res[0];
      const stationData: StationDetail[] = stations.map((station) => {
        return { ...station, distance, latitude, longitude };
      });
      setGlobalChargerStationList(stationData);
      setIsSearchChargerStation(true);
      navigate("/");
    });
  };

  const handleHistoryAllClearButtonClick = () => {
    removeAllLocalRecentSearchValue();
    setRecentSearchValue([]);
  };

  const handleHistoryItemClearButtonClick = (item: ResponseStationSearch) => {
    const filteredRecentSearchValue = recentSearchValue.filter(
      (data) => data.stationId !== item.stationId,
    );

    setRecentSearchValue(filteredRecentSearchValue);
    removeLocalRecentSearchValue(item.stationId);
  };

  return (
    <Layout disableHeader>
      <SearchInputContainer>
        <BackButton onClick={handleBackButtonClick} />
        <Input
          focused
          placeholder={"충전소명, 주소 검색"}
          value={searchAddress}
          onChange={handleSearchAddressChange}
        />
        {/* 검색어 있을 경우 */}
        {searchAddress && <ClearButton onClick={handleClearButtonClick} />}
      </SearchInputContainer>
      <SearchCategory $selectCategory={selectedCategory}>
        <span ref={categoryRef1} onClick={() => handleCategoryClick("충전소")}>
          충전소
        </span>
        <span ref={categoryRef2} onClick={() => handleCategoryClick("주소")}>
          주소
        </span>
        <UnderBar
          $categoryWidth={categoryWidth}
          $selectedCategory={selectedCategory}
        />
      </SearchCategory>
      <ChargerListContainer>
        {/* 최근 검색어 */}

        {isLoading ? (
          <LottieContainer>
            <Lottie lottieName="global-loading" width={120} height={120} />
          </LottieContainer>
        ) : (
          <>
            {!searchData && !isError && (
              <>
                {recentSearchValue.length === 0 ? (
                  <EmptyPlaceholder
                    descriptionStyle={{ fontWeight: 400 }}
                    description="최근 검색 내역이 없어요."
                    height={`${height - 112}px`}
                  />
                ) : (
                  <>
                    <SearchHistoryHeader>
                      <h3>최근 검색어</h3>
                      <Button
                        btnType="text"
                        onClick={handleHistoryAllClearButtonClick}
                      >
                        전체삭제
                      </Button>
                    </SearchHistoryHeader>
                    <SearchHistoryList height={height}>
                      {recentSearchValue.map((stationData, index) => {
                        return (
                          <HistoryItem
                            key={index}
                            stationData={stationData}
                            onClick={() => handleHistoryItemClick(stationData)}
                            onClear={() =>
                              handleHistoryItemClearButtonClick(stationData)
                            }
                          />
                        );
                      })}
                    </SearchHistoryList>
                  </>
                )}
              </>
            )}

            {searchData ? (
              <ChargerBasicInfoListContainer height={height}>
                {searchData.map((item, index) => (
                  <ChargerBasicInfoCard
                    key={index}
                    hasBottomLine
                    selectedCategory={selectedCategory}
                    stationName={item.stationName}
                    stationAddress={item.address}
                    stationBrand={item.stationBrand}
                    distance={item.distance}
                    onClick={() => handleChargerBasicInfoCardItemClick(item)}
                    searchAddress={searchAddress}
                  />
                ))}
              </ChargerBasicInfoListContainer>
            ) : (
              <DataEmptyPlaceholder
                title="검색 결과가 없어요."
                description="충전소 이름을 한 글자 이상 작성해주세요."
              />
            )}
          </>
        )}
      </ChargerListContainer>
    </Layout>
  );
};

export default SearchAddress;

const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 18px 16px;
`;

const BackButton = styled(Arrow)`
  min-width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ClearButton = styled(Delete)`
  min-width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SearchCategory = styled.div<{ $selectCategory: string }>`
  position: relative;
  height: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  border: 1px solid ${COLORS.GRAY10};
  border-left: unset;
  border-right: unset;

  span {
    ${TYPO.BODY_6};
    color: ${COLORS.GRAY6};
    cursor: pointer;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in;

    &:nth-child(1) {
      color: ${({ $selectCategory }) =>
        $selectCategory === "충전소" ? COLORS.PRIMARY : COLORS.GRAY6};
    }

    &:nth-child(2) {
      color: ${({ $selectCategory }) =>
        $selectCategory === "주소" ? COLORS.PRIMARY : COLORS.GRAY6};
    }
  }
`;

const ChargerListContainer = styled.div``;

const SearchHistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px 8px 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );

  > h3 {
    width: 100%;
    ${TYPO.HEADING_6};
    color: ${COLORS.GRAY2};
  }
`;

const SearchHistoryList = styled.div<{ height: number }>`
  /* height: 448px; */
  height: ${({ height }) => height - 168}px;
  overflow-y: auto;
`;

const ChargerBasicInfoListContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height - 112}px;
  overflow-y: auto;
`;

const UnderBar = styled.div<{
  $categoryWidth?: (number | undefined)[];
  $selectedCategory?: SearchCategoryType;
}>`
  position: absolute;
  display: inline-block;
  bottom: 0px;
  height: 2px;
  background-color: ${COLORS.PRIMARY};
  transition: all 0.3s ease-in;
  width: ${({ $categoryWidth, $selectedCategory }) => {
    if ($categoryWidth) {
      if ($selectedCategory === "충전소") {
        return `${$categoryWidth[0]}px`;
      }
      return `${$categoryWidth[1]}px`;
    }
  }};
  left: ${({ $categoryWidth, $selectedCategory }) => {
    if ($categoryWidth) {
      if ($selectedCategory === "충전소") {
        return `20px`;
      } else return `80px`;
    }
  }};
`;

interface ChargerCardProps {
  hasBrand?: boolean;
  disabledContainerPadding?: boolean;
  hasBottomLine?: boolean;
  stationName: string;
  stationAddress: string;
  stationAddressDetail?: string;
  distance?: number;
  stationBrand: StationBrandCode;
  searchAddress?: string;
  selectedCategory?: SearchCategoryType;
  onClick?: () => void;
}

export const ChargerBasicInfoCard = ({
  searchAddress,
  selectedCategory,
  hasBrand = false,
  disabledContainerPadding = false,
  hasBottomLine = false,
  stationName,
  stationAddress,
  stationAddressDetail,
  stationBrand,
  distance,
  onClick,
}: ChargerCardProps) => {
  const chargerStationAddress = `${stationAddress || ""} ${
    stationAddressDetail || ""
  }`;

  return (
    <ChargerCardContainer
      $disabledContainerPadding={disabledContainerPadding}
      $hasBottomLine={hasBottomLine}
      onClick={onClick}
    >
      <ChargerName $hasBrand={hasBrand}>
        {hasBrand && <BrandLogoRender stationBrand={stationBrand} />}
        {searchAddress &&
        selectedCategory === "충전소" &&
        stationName.includes(searchAddress) ? (
          <p>
            {stationName.split(searchAddress)[0]}
            <Highlight>{searchAddress}</Highlight>
            {stationName.split(searchAddress)[1]}
          </p>
        ) : (
          <p>{stationName}</p>
        )}
      </ChargerName>

      <ChargerDistance>
        {distance &&
          (distance < 1000 ? distance : (distance / 1000).toFixed(1))}
        {distance && distance < 1000 ? "m" : "km"}
      </ChargerDistance>

      <ChargerAddress>
        {searchAddress &&
        selectedCategory === "주소" &&
        ((stationAddress || stationAddressDetail) ?? "").includes(
          searchAddress,
        ) ? (
          <p>
            {chargerStationAddress.split(searchAddress)[0]}
            <AddressHighlight>{searchAddress}</AddressHighlight>
            {chargerStationAddress.split(searchAddress)[1]}
          </p>
        ) : (
          `${stationAddress || ""} ${stationAddressDetail || ""}`
        )}
      </ChargerAddress>
    </ChargerCardContainer>
  );
};

const ChargerCardContainer = styled.div<{
  $disabledContainerPadding: boolean;
  $hasBottomLine: boolean;
}>`
  cursor: pointer;
  ${({ $disabledContainerPadding }) => {
    if ($disabledContainerPadding) {
      return `
        padding: 0;
      `;
    } else {
      return `
        padding: 20px;
      `;
    }
  }}

  ${({ $hasBottomLine }) => {
    if ($hasBottomLine) {
      return `
        border-bottom: 1px solid ${COLORS.GRAY10};
      `;
    }
  }}
`;

const ChargerName = styled.div<{ $hasBrand: boolean }>`
  display: flex;
  align-items: center;

  ${TYPO.BODY_6};
  margin-bottom: 8px;

  ${({ $hasBrand }) =>
    $hasBrand &&
    css`
      gap: 8px;
    `}
`;

const Highlight = styled.span`
  color: ${COLORS.PRIMARY};
  ${TYPO.HEADING_6};
`;

const AddressHighlight = styled.span`
  color: ${COLORS.PRIMARY};
  ${TYPO.HEADING_8};
`;

const ChargerDistance = styled.div`
  color: ${COLORS.GRAY6};
  margin-bottom: 4px;
  ${TYPO.BODY_8};
`;

const ChargerAddress = styled.div`
  ${TYPO.BODY_8}
  color: ${COLORS.GRAY6};
  font-weight: 400;
  max-height: 40px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  margin-bottom: 8px;
`;

const LottieContainer = styled.div`
  height: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`;
