import { DistrictsType, requestStationDistricts } from "@apis/map";
import { ReactComponent as Arrow } from "@assets/icons/arrow-down.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { Button } from "@components/atoms/Button";
import { Input } from "@components/atoms/Input";
import { SearchLocationBottomSheet } from "@components/molecules/SearchLocationBottomSheet";
import Layout from "@components/templates/Layout";
import useLocationStore from "@store/useLocationStore";
import { COLORS, TYPO } from "@styles/index";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const Search: React.FC = () => {
  const navigate = useNavigate();
  const [isSearchLocationBottomOpen, setIsLocationBottomSheetOpen] =
    useState(false);
  const {
    globalCurrentLatitude,
    globalCurrentLongitude,
    setSearchLocation,
    setIsSearch,
  } = useLocationStore((state) => state);

  const [selectSido, setSelectSido] = useState<DistrictsType>({
    district: "",
    latitude: 0,
    longitude: 0,
  });
  const [selectGugun, setSelectGugun] = useState<DistrictsType>({
    district: "",
    latitude: 0,
    longitude: 0,
  });

  const { data: locationData = [] } = useQuery(
    "stationDistricts",
    requestStationDistricts,
    {
      select: (data) => {
        return [
          {
            sido: {
              district: "전체",
              latitude: globalCurrentLatitude || 0,
              longitude: globalCurrentLongitude || 0,
            },
            gugun: [],
          },
          ...data,
        ];
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: selectSido.district === "" && selectGugun.district === "",
    },
  );

  const handleBackButtonClick = () => {
    navigate("/");
  };

  const handleLocationSelectClick = () => {
    setIsLocationBottomSheetOpen(!isSearchLocationBottomOpen);
    if (!selectGugun.district) return;
    else {
      setSelectSido({
        district: "",
        latitude: 0,
        longitude: 0,
      });
      setSelectGugun({
        district: "",
        latitude: 0,
        longitude: 0,
      });
    }
  };

  const handleSearchAddressClick = () => {
    navigate("/search/address");
  };

  const handleSearchButtonClick = () => {
    if (selectGugun.district) {
      setSearchLocation(selectGugun.latitude, selectGugun.longitude);
    } else {
      setSearchLocation(selectSido.latitude, selectSido.longitude);
    }

    setIsSearch(true);
    // 지도 이동
    navigate("/");
  };

  const selectLocationRender = () => {
    if (selectSido.district || selectGugun.district) {
      if (selectSido.district === "전체") return "전체";
      if (selectGugun.district === "전체") return selectSido.district;

      return `${selectSido.district} ${selectGugun.district}`;
    } else {
      return "지역을 선택해주세요.";
    }
  };

  return (
    <>
      <Layout
        headerTitle="충전소 찾기"
        hasBackButton
        onBackButtonClick={handleBackButtonClick}
      >
        <SearchContainer>
          <SearchAddressArea>
            <SearchInput onClick={handleSearchAddressClick}>
              <SearchIcon />
              <Input placeholder={"충전소명, 주소 검색"} />
            </SearchInput>
          </SearchAddressArea>

          <SearchLocationArea>
            <h3 className="title">지역</h3>
            <SearchLocationSelect onClick={handleLocationSelectClick}>
              {selectLocationRender()}
              <StyledArrow $active={isSearchLocationBottomOpen} />
            </SearchLocationSelect>
          </SearchLocationArea>
        </SearchContainer>
        <ButtonContainer>
          <Button
            btnType="fill"
            onClick={handleSearchButtonClick}
            disabled={!selectGugun.district}
          >
            검색하기
          </Button>
        </ButtonContainer>
      </Layout>
      <SearchLocationBottomSheet
        selectGugun={selectGugun.district}
        selectSido={selectSido.district}
        setSelectGugun={setSelectGugun}
        setSelectSido={setSelectSido}
        locationData={locationData}
        isOpen={isSearchLocationBottomOpen}
        onCloseBottomSheet={handleLocationSelectClick}
      />
    </>
  );
};

export default Search;

const SearchContainer = styled.div`
  padding-top: 60px;
  background: ${COLORS.GRAY10};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SearchAddressArea = styled.div`
  background: ${COLORS.WHITE};
  padding: 24px 20px;
`;

const SearchInput = styled.div`
  display: flex;
  padding: 14px 16px;
  gap: 12px;
  border-radius: 16px;
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 8px 0px rgba(53, 58, 63, 0.12);
`;

const SearchLocationArea = styled.div`
  background: ${COLORS.WHITE};
  padding: 24px 20px;

  .title {
    ${TYPO.HEADING_6}
    margin-bottom: 16px;
  }
`;

const SearchLocationSelect = styled.div`
  background: ${COLORS.GRAY11};
  padding: 12px 16px;
  border-radius: 12px;
  ${TYPO.BODY_6};
  color: ${COLORS.GRAY6};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StyledArrow = styled(Arrow)<{ $active: boolean }>`
  transform: ${({ $active }) => ($active ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
`;

const ButtonContainer = styled.div`
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;
