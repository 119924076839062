import { COLORS } from "@styles/index";
import { styled } from "styled-components";

interface IconBoxProps {
  icon: React.ReactNode;
  onClick: () => void;
}

const IconButton: React.FC<IconBoxProps> = ({ icon, onClick }) => {
  return <StyledButton onClick={onClick}>{icon}</StyledButton>;
};

const StyledButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 2px 8px 0px rgba(53, 58, 63, 0.12);
`;

export default IconButton;
