import { requestChargerDetail } from "@apis/map";
import BrandLogoRender from "@components/atoms/BrandLogoRender";
import { ChargerDetailInfoCard } from "@components/molecules/ChargerDetailInfoCard";
import { ChargerState } from "@components/molecules/ChargerState";
import { NavigationButtonLender } from "@components/molecules/NavigationButtonLender";
import {
  RedirectLocationData,
  RedirectNavigationBottomSheet,
} from "@components/molecules/RedirectNavigationBottomSheet";
import Layout from "@components/templates/Layout";
import useGlobalModal from "@hooks/useGlobalModal";
import useLocationStore from "@store/useLocationStore";
import { COLORS, TYPO } from "@styles/index";
import { phoneCall } from "@utils/data-fn.utils";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const ChargerDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [
    isRedirectNavigationBottomSheetOpen,
    setRedirectNavigationBottomSheetOpen,
  ] = useState(false);

  const { globalCurrentLatitude, globalCurrentLongitude } = useLocationStore(
    (state) => state,
  );

  const { hideModal, showLoadingModal } = useGlobalModal();

  const query = { ...queryString.parse(location.search) };

  const { data: chargerStationData, isLoading } = useQuery(
    ["chargerDetail", query.stationId, query.stationBrand],
    () =>
      requestChargerDetail(
        query.stationId as never,
        query.stationBrand as never,
      ),
    {
      onError: () => {
        navigate("/error", {
          replace: true,
          state: { beforeUrl: location.pathname },
        });
      },
      enabled: !!query,
    },
  );

  const navigationButtonStyle: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "32px 20px 20px 20px",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.85) 80%, rgba(255,255,255,0.30) 90%, rgba(255,255,255,0) 100%)",
  };

  const stationAddress = `${chargerStationData?.address} ${chargerStationData?.addressDetail}`;

  const redirectLocationData = () => {
    if (
      !globalCurrentLatitude ||
      !globalCurrentLongitude ||
      !chargerStationData
    )
      return;

    const LocationData: RedirectLocationData = {
      currentStationName: chargerStationData?.stationName,
      currentAddress: chargerStationData?.address,
      currentLatitude: globalCurrentLatitude,
      currentLongitude: globalCurrentLongitude,
      targetLatitude: chargerStationData?.latitude,
      targetLongitude: chargerStationData?.longitude,
    };

    return LocationData;
  };

  const handelBackButtonClick = () => {
    navigate("/");
  };

  const handleNavigationButtonClick = () => {
    setRedirectNavigationBottomSheetOpen(true);
  };

  // NOTE : 로딩 모달
  useEffect(() => {
    if (!isLoading) hideModal();
    else {
      showLoadingModal();
      setTimeout(() => {
        hideModal();
      }, 2000);
    }
  }, [hideModal, isLoading, showLoadingModal]);

  return (
    <>
      <Layout
        hasBackButton
        headerTitle=""
        onBackButtonClick={handelBackButtonClick}
      >
        <DetailContainer>
          <ChargerStationBasicArea>
            <div className="chargerNameContainer">
              {chargerStationData?.stationBrandCode && (
                <BrandLogoRender
                  stationBrand={chargerStationData.stationBrandCode}
                />
              )}
              <h2>{chargerStationData?.stationName}</h2>
            </div>
            <ChargerState
              status={chargerStationData?.status || "canUse"}
              usableFastChargerCount={
                chargerStationData?.usableFastChargerCount || 0
              }
              usableLateChargerCount={
                chargerStationData?.usableLateChargerCount || 0
              }
              lateChargerTotalCount={
                chargerStationData?.lateChargerTotalCount || 0
              }
              fastChargerTotalCount={
                chargerStationData?.fastChargerTotalCount || 0
              }
            />
          </ChargerStationBasicArea>

          <ChargerStationDetailArea>
            <p className="detailInfoTitle">상세정보</p>

            <div className="detailInfoContainer">
              <InfoItem>
                <p className="label">주소</p>
                <p className="description">
                  {chargerStationData?.address || "-"}
                </p>
              </InfoItem>
              <InfoItem>
                <p className="label">상세위치</p>
                <p className="description">
                  {chargerStationData?.addressDetail || "-"}
                </p>
              </InfoItem>
              <InfoItem>
                <p className="label">운영기관</p>
                <p className="description">
                  {chargerStationData?.stationBrandName || "-"}
                </p>
              </InfoItem>
              <InfoItem>
                <p className="label">연락처</p>
                <p
                  className="phoneNumber description"
                  onClick={() => {
                    if (chargerStationData?.cpoContact)
                      phoneCall(`${chargerStationData?.cpoContact}`);
                  }}
                >
                  {chargerStationData?.cpoContact || "-"}
                </p>
              </InfoItem>
              <InfoItem>
                <p className="label">충전기 유형</p>
                <p className="description">
                  {chargerStationData?.chargerTypeName || "-"}
                </p>
              </InfoItem>
              <InfoItem>
                <p className="label">운영시간</p>
                <p className="description">
                  {chargerStationData?.businessHoursInfo || "-"}
                </p>
              </InfoItem>
            </div>
          </ChargerStationDetailArea>

          <ChargerListArea>
            <ChargerListTitleContainer>
              <p className="chargerListTitle">충전기 목록</p>
              <span className="chargerCount">
                총 {chargerStationData?.chargerList.length || 0}개
              </span>
            </ChargerListTitleContainer>
            <ChargerListContainer>
              {/* 카드 */}
              {chargerStationData?.chargerList.map((charger) => {
                return (
                  <ChargerDetailInfoCard
                    key={charger.chargerId}
                    chargerData={charger}
                  />
                );
              })}
            </ChargerListContainer>
          </ChargerListArea>
        </DetailContainer>
        <NavigationButtonLender
          style={navigationButtonStyle}
          onNavigateButtonClick={handleNavigationButtonClick}
          copyText={stationAddress}
        />
      </Layout>
      <RedirectNavigationBottomSheet
        isOpen={isRedirectNavigationBottomSheetOpen}
        onClose={setRedirectNavigationBottomSheetOpen}
        redirectLocationData={redirectLocationData()}
      />
    </>
  );
};

export default ChargerDetail;

const DetailContainer = styled.div`
  padding-top: 60px;
  background: ${COLORS.GRAY10};
  height: auto;
`;

const ChargerStationBasicArea = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${COLORS.WHITE};

  .chargerNameContainer {
    ${TYPO.BODY_3}
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const ChargerStationDetailArea = styled.div`
  margin-top: 12px;
  background: ${COLORS.WHITE};
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;

  .detailInfoTitle {
    ${TYPO.HEADING_5};
  }

  .detailInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  ${TYPO.BODY_6};
  font-weight: 400;
  gap: 16px;

  .label {
    color: ${COLORS.GRAY6};
    flex: 1;
    max-width: 80px;
    min-width: fit-content;
  }

  .description {
    color: ${COLORS.GRAY2};
    flex: 3;
    text-align: right;
    height: auto;
    width: 100%;
    white-space: pre-line;
    word-wrap: break-word;
  }

  .phoneNumber {
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;
  }
`;

const ChargerListArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 20px 110px 20px;
`;

const ChargerListTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 8px;

  .chargerListTitle {
    ${TYPO.HEADING_5};
    color: ${COLORS.GRAY2};
  }

  .chargerCount {
    color: ${COLORS.GRAY6};
    ${TYPO.BODY_10};
    font-weight: 400;
  }
`;

const ChargerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
