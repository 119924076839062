import React from "react";
import BaseModal from "../BaseModal";
import { styled } from "styled-components";
import Lottie from "@components/atoms/Lottie";

export interface LoadingModalProps {}

const LoadingModal: React.FC<LoadingModalProps> = ({}) => {
  return (
    <BaseModal isOpen={true}>
      <LottieContainer>
        <Lottie lottieName="global-loading" width={120} height={120} />
      </LottieContainer>
    </BaseModal>
  );
};

export default LoadingModal;

const LottieContainer = styled.div`
  height: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`;
