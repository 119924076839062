import { Z_INDEX } from "@styles/index";
import React, { PropsWithChildren, useEffect, useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

interface BottomSheetProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  contentsStyle?: React.CSSProperties;
  disableLine?: boolean;
  disableScroll?: boolean;
  height?: string;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  isOpen,
  contentsStyle,
  disableLine = false,
  disableScroll = false,
  height,
  onClose,
}) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const [contentBottomSheetHeight, setContentBottomSheetHeight] = useState(0);

  const handleContentsBottomSheetHeight = (instance: HTMLDivElement) => {
    if (!instance) setContentBottomSheetHeight(0);
    if (instance?.clientHeight) {
      setContentBottomSheetHeight(instance.clientHeight);
    }
  };

  const handleClose = () => {
    setIsAnimation(false);
    setTimeout(() => {
      onClose(false);
    }, 500);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setIsAnimation(true);
      }, 100);
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const customStyles = {
    overlay: {
      background: "transparent",
      zIndex: Z_INDEX.BOTTOM_SHEET_OVERLAY,
    },
    content: contentsStyle,
  };

  return (
    <StyledBottomSheet
      contentRef={handleContentsBottomSheetHeight}
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
      disableScroll={disableScroll}
      height={height}
      isAnimation={isAnimation}
      contentHeight={contentBottomSheetHeight}
    >
      {!disableLine && (
        <BottomSheetLine onClick={handleClose}>
          <Line />
        </BottomSheetLine>
      )}
      <ContentsContainer>{children} </ContentsContainer>
    </StyledBottomSheet>
  );
};

export default BottomSheet;

const StyledBottomSheet = styled(ReactModal)<{
  disableScroll?: boolean;
  height?: string;
  isAnimation: boolean;
  contentHeight: number;
}>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: ${({ height }) => (height ? `${height}` : "60vh")};
  max-height: 80vh;
  background: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); // bottom shadow
  outline: none;
  transition: all 0.5s ease;

  ${({ disableScroll }) => {
    if (disableScroll) {
      return `
        overflow: hidden;
      `;
    } else {
      return `
        overflow: auto;
      `;
    }
  }}

  ${({ isAnimation, contentHeight }) => {
    if (isAnimation && contentHeight > 0) {
      return `
        bottom: 0;
        opacity: 1;
      `;
    } else {
      return `
        bottom: -${contentHeight}px;
        opacity: 0;
      `;
    }
  }}
`;

const BottomSheetLine = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 20px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: ${Z_INDEX.BOTTOM_SHEET_LINE};
`;

const Line = styled.span`
  position: absolute;
  display: block;
  width: 48px;
  height: 4px;
  background-color: #ccc;
  border-radius: 8px;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
`;

const ContentsContainer = styled.div`
  /* border: 2px dotted gray; */
  position: relative;
  overflow-y: auto;
  /* height: 100%; */
`;
