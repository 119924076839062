import { DistrictsType, ResponseStationDistricts } from "@apis/map";
import BottomSheet from "@components/atoms/BottomSheet";
import { COLORS, TYPO } from "@styles/index";
import { styled } from "styled-components";

interface SearchLocationBottomSheetProps {
  isOpen: boolean;
  onCloseBottomSheet: () => void;
  locationData?: ResponseStationDistricts[];
  selectSido: string;
  selectGugun: string;
  setSelectSido: React.Dispatch<React.SetStateAction<DistrictsType>>;
  setSelectGugun: React.Dispatch<React.SetStateAction<DistrictsType>>;
}

export const SearchLocationBottomSheet: React.FC<
  SearchLocationBottomSheetProps
> = ({
  isOpen,
  selectGugun,
  selectSido,
  locationData = [],
  setSelectGugun,
  setSelectSido,
  onCloseBottomSheet,
}) => {
  if (!locationData) return null;

  const sidoArray = locationData?.map((item) => item.sido);

  const gugunArray = () => {
    if (selectSido && locationData) {
      const sigoData = locationData?.find(
        (item) => item.sido.district === selectSido,
      )?.sido;

      const gugunArray = locationData?.find(
        (item) => item.sido.district === selectSido,
      )?.gugun;

      if (gugunArray) {
        return [
          {
            district: "전체",
            latitude: sigoData?.latitude || 0,
            longitude: sigoData?.longitude || 0,
          },
          ...gugunArray,
        ];
      }
    }
  };

  const handleSidoClick = (sido: DistrictsType) => {
    setSelectSido(sido);
    setSelectGugun({
      district: "",
      latitude: 0,
      longitude: 0,
    });
  };

  const handleGugunClick = (gugun: DistrictsType) => {
    setSelectGugun(gugun);
    onCloseBottomSheet();
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onCloseBottomSheet}
      disableScroll
      height={`512px`}
    >
      <LocationTitle>지역 선택</LocationTitle>

      <LocationSelectContainer>
        <WideArea>
          {sidoArray.map((sido: DistrictsType) => {
            return (
              <Item
                key={sido.district}
                onClick={() => handleSidoClick(sido)}
                $isSelected={selectSido === sido.district}
              >
                {sido.district}
              </Item>
            );
          })}
        </WideArea>

        <AutonomyArea>
          {selectSido &&
            gugunArray()?.map((gugun: DistrictsType) => {
              return (
                <Item
                  key={gugun.district}
                  onClick={() => handleGugunClick(gugun)}
                  $isSelected={selectGugun === gugun.district}
                >
                  {gugun.district}
                </Item>
              );
            })}
        </AutonomyArea>
      </LocationSelectContainer>
    </BottomSheet>
  );
};

const LocationTitle = styled.p`
  ${TYPO.HEADING_4}
  color: ${COLORS.GRAY2};
  padding: 16px 20px;
`;

const LocationSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLORS.GRAY10};
  height: auto;
  overflow: hidden;
  background: ${COLORS.WHITE};
`;

const WideArea = styled.div`
  flex: 1;
  max-width: 50%;
  max-height: ${48 * 9}px;
  overflow-y: auto;
  border-right: 1px solid ${COLORS.GRAY10};
`;

const Item = styled.span<{ $isSelected?: boolean }>`
  display: inline-flex;
  padding: 12px 20px;
  width: 100%;
  height: 48px;
  align-items: center;
  cursor: pointer;

  background: ${({ $isSelected }) =>
    $isSelected ? COLORS.PRIMARY : COLORS.WHITE};
  color: ${({ $isSelected }) => ($isSelected ? COLORS.WHITE : COLORS.BLACK)};

  &:active {
    background: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
  }
`;

const AutonomyArea = styled.div`
  flex: 1;
  max-width: 50%;
  max-height: ${48 * 9}px;
  height: 100%;
  overflow-y: auto;
`;
