import useWindowSize from "@hooks/useWindowSize";
import { COLORS, Z_INDEX } from "@styles/index";
import { motion, useAnimation } from "framer-motion";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

interface AnimationBottomSheetProps extends PropsWithChildren {
  disableLine?: boolean;
  onClose?: () => void;
  maxHeight?: string;
  isOpened: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  height?: number;
  chargerListLength?: number;
}

const AnimationBottomSheet: React.FC<AnimationBottomSheetProps> = ({
  disableLine = false,
  children,
  maxHeight,
  isOpened,
  chargerListLength,
  height,
  setIsOpen,
  onClose,
}) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const { height: screenHeight } = useWindowSize();
  const controls = useAnimation();
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(0);
  const [dragDirection, setDragDirection] = useState<"up" | "down" | "">("");
  const [bottomSheetLocationCheck, setBottomSheetLocationCheck] = useState<
    "top" | "middle" | "hidden"
  >("middle");
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const bottomSheetContainerHeight = screenHeight - 100;

  useEffect(() => {
    controls.start("hidden");
  }, []);

  useEffect(() => {
    if (isOpened) {
      if (chargerListLength && chargerListLength < 3) {
        setBottomSheetLocationCheck("top");
      } else {
        controls.start("middle");
      }
    }
  }, [chargerListLength, isOpened]);

  // NOTE : 스크롤 방향에 감지하여 값을 저장
  useEffect(() => {
    if (startPoint === 0 || endPoint === 0) return;
    setDragDirection(startPoint > endPoint ? "up" : "down");
  }, [endPoint]);

  // NOTE : 스크롤 방향에 따라 bottomSheetLocationCheck 값 변경
  useEffect(() => {
    if (startPoint === 0 || endPoint === 0) return;

    switch (dragDirection) {
      case "up": {
        if (bottomSheetLocationCheck === "middle" && !isScroll) {
          setBottomSheetLocationCheck("top");
        }
        break;
      }
      case "down": {
        if (chargerListLength && chargerListLength < 3) {
          setBottomSheetLocationCheck("hidden");
          break;
        } else if (bottomSheetLocationCheck === "top" && !isScroll) {
          setBottomSheetLocationCheck("middle");
        } else if (bottomSheetLocationCheck === "middle" && !isScroll) {
          setBottomSheetLocationCheck("hidden");
        }
        break;
      }
      case "": {
        break;
      }
      default: {
        break;
      }
    }

    setDragDirection("");
  }, [dragDirection]);

  // NOTE : bottomSheetLocationCheck 값이 변할 때마다 실행
  useEffect(() => {
    switch (bottomSheetLocationCheck) {
      case "top": {
        controls.start("top");
        break;
      }
      case "middle": {
        controls.start("middle");
        break;
      }
      case "hidden": {
        controls.start("hidden").then(() => {
          if (setIsOpen) setIsOpen(false);
          if (onClose) onClose();
        });
        break;
      }
    }
  }, [bottomSheetLocationCheck]);

  return (
    <AnimationDiv
      $maxHeight={maxHeight || `${bottomSheetContainerHeight}px`}
      $height={height}
      drag="y"
      initial="hidden"
      animate={controls}
      dragMomentum={false}
      dragConstraints={{ top: 0 }}
      ref={bottomSheetRef}
      transition={{
        type: "spring",
        damping: 40,
        stiffness: 400,
      }}
      variants={{
        top: { y: "0%", opacity: 1 },
        middle: { y: "50%", opacity: 1 },
        hidden: { y: "100%", opacity: 0.8 },
      }}
      style={{ touchAction: "none" }}
      onDragStart={(event, info) => {
        setStartPoint(info.point.y);
      }}
      onDragEnd={(event, info) => {
        setEndPoint(info.point.y);
      }}
      onScroll={() => {
        if (!isScroll) setIsScroll(true);
        if (isScroll) {
          setTimeout(() => {
            setIsScroll(false);
          }, 1500);
        }
      }}
    >
      {!disableLine && (
        <BottomSheetLine>
          <Line />
        </BottomSheetLine>
      )}
      <ContentsContainer style={{ touchAction: "none" }}>
        {children}
      </ContentsContainer>
    </AnimationDiv>
  );
};

export default AnimationBottomSheet;

const AnimationDiv = styled(motion.div)<{
  $maxHeight?: string;
  $height?: number;
}>`
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : "auto")};
  max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}` : "50vh")};
  position: fixed;
  bottom: 0;
  background: ${COLORS.WHITE};
  overflow: auto;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  z-index: ${Z_INDEX.BOTTOM_SHEET};
`;

const BottomSheetLine = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 30px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: ${Z_INDEX.BOTTOM_SHEET_LINE};
`;

const Line = styled.span`
  position: absolute;
  display: block;
  width: 48px;
  height: 4px;
  background-color: #ccc;
  border-radius: 8px;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const ContentsContainer = styled.div`
  position: relative;
  overflow-y: auto;
`;

export function usePrevious(value: any) {
  const previousValueRef = useRef();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
}
