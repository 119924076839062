import { ResponseStationSearch } from "@apis/map/types";
import { ReactComponent as Close } from "@assets/icons/close.svg";
import { ReactComponent as Search } from "@assets/icons/search.svg";
import { TYPO } from "@styles/index";
import { styled } from "styled-components";

interface HistoryItemProps {
  stationData: ResponseStationSearch;
  onClick: () => void;
  onClear: () => void;
}

export const HistoryItem = ({
  stationData,
  onClick,
  onClear,
}: HistoryItemProps) => {
  const { stationName } = stationData;
  return (
    <HistoryItemContainer onClick={onClick}>
      <div className="historyTitleArea">
        <Search />
        <p>{stationName}</p>
      </div>
      <HistoryItemDeleteButton
        onClick={(e) => {
          e.stopPropagation();
          onClear();
        }}
      />
    </HistoryItemContainer>
  );
};

const HistoryItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;

  .historyTitleArea {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    ${TYPO.BODY_6};
    font-weight: 400;
  }
`;

const HistoryItemDeleteButton = styled(Close)`
  cursor: pointer;
  z-index: 1;
`;
