import { ReactComponent as EmptyIcon } from "@assets/icons/empty-placeholder.svg";
import { COLORS, TYPO } from "@styles/index";
import { styled } from "styled-components";

interface EmptyPlaceholderProps {
  title?: string;
  description: string;
  descriptionStyle?: React.CSSProperties;
  height?: string;
}

export const EmptyPlaceholder = ({
  title,
  description,
  descriptionStyle,
  height,
}: EmptyPlaceholderProps) => {
  return (
    <EmptyContainer height={height}>
      <EmptyIcon />
      <div>
        {title && <p>{title}</p>}
        <small style={descriptionStyle}>{description}</small>
      </div>
    </EmptyContainer>
  );
};

const EmptyContainer = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: ${({ height }) => (height ? height : "47vh")};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: ${COLORS.GRAY6};

    p {
      ${TYPO.HEADING_8};
    }

    small {
      ${TYPO.BODY_8};
    }
  }
`;
