import { ReactComponent as NotiIcon } from "@assets/icons/light-bulb.svg";
import useWindowSize from "@hooks/useWindowSize";
import { StationDetail } from "@pages/Map";
import useLocationStore from "@store/useLocationStore";
import { COLORS, TYPO } from "@styles/index";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css, styled } from "styled-components";
import { ChargerCard } from "../ChargerCard";
import { NavigationButtonLender } from "../NavigationButtonLender";
import {
  RedirectLocationData,
  RedirectNavigationBottomSheet,
} from "../RedirectNavigationBottomSheet";
import { StationBrandCode } from "@apis/map";
import useChargerStationStore from "@store/useChargerStationStore";

interface DetailChargerCardProps {
  // TODO : props 정의
  stations: StationDetail[];
}

export const DetailChargerCard = ({ stations }: DetailChargerCardProps) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [
    isRedirectNavigationBottomSheetOpen,
    setRedirectNavigationBottomSheetOpen,
  ] = useState(false);
  const {
    globalCurrentLatitude,
    globalCurrentLongitude,
    setIsDetailChargerStation,
    setGlobalDetailLocation,
  } = useLocationStore((state) => state);
  const { setGlobalChargerStationList } = useChargerStationStore(
    (state) => state,
  );

  const isStationOne = stations.length === 1;
  const stationAddress = `${stations[0].address} ${stations[0].addressDetail}`;
  const redirectLocationData = () => {
    if (!isStationOne) return;

    if (globalCurrentLatitude && globalCurrentLongitude) {
      const {
        address: currentAddress,
        stationName: currentStationName,
        longitude: targetLongitude,
        latitude: targetLatitude,
      } = stations[0];

      const LocationData: RedirectLocationData = {
        currentStationName,
        currentAddress,
        currentLatitude: globalCurrentLatitude,
        currentLongitude: globalCurrentLongitude,
        targetLatitude,
        targetLongitude,
      };

      return LocationData;
    }
  };

  const handleNavigateButtonClick = () => {
    setRedirectNavigationBottomSheetOpen(true);
  };

  const handleChargerCardClick = (
    stationId: string,
    stationBrand: StationBrandCode,
  ) => {
    const { longitude, latitude } = stations[0];

    setIsDetailChargerStation(true);
    setGlobalDetailLocation(latitude, longitude);
    setGlobalChargerStationList([stations[0]]);

    navigate(`/detail?stationId=${stationId}&stationBrand=${stationBrand}`);
  };

  return (
    <>
      {stations.map((station) => {
        const { stationId, stationBrand } = station;

        return (
          <ChargerCard
            key={station.stationId}
            onClick={() => handleChargerCardClick(stationId, stationBrand)}
            station={station}
          />
        );
      })}

      {isStationOne ? (
        <NavigationButtonLender
          onNavigateButtonClick={handleNavigateButtonClick}
          copyText={stationAddress}
        />
      ) : (
        <NotiContainer $windowWidth={width}>
          <NotiIcon />
          <p>
            {`선택한 위치에 충전소가 여러 개 있어요!\n충전소를 한번 더 선택해 주세요.`}
          </p>
        </NotiContainer>
      )}

      <RedirectNavigationBottomSheet
        isOpen={isRedirectNavigationBottomSheetOpen}
        onClose={setRedirectNavigationBottomSheetOpen}
        redirectLocationData={redirectLocationData()}
      />
    </>
  );
};

const NotiContainer = styled.div<{ $windowWidth: number }>`
  background: #fff7d9;
  margin: 0 20px 20px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  color: ${COLORS.GRAY2};

  > p {
    ${TYPO.BODY_9};

    ${({ $windowWidth }) => {
      if ($windowWidth < 458) {
        return css`
          word-break: keep-all;
          white-space: pre-wrap;
        `;
      }
    }}
  }
`;
