import { ResponseCustomerStation } from "@apis/map";
import Supercluster, { AnyProps, PointFeature } from "supercluster";

const supercluster = (
  arr: ResponseCustomerStation[],
  zoomLevel: number,
  cameraLotation: {
    topLeftLon: number;
    bottomRightLon: number;
    topLeftLat: number;
    bottomRightLat: number;
  },
) => {
  const result: PointFeature<AnyProps>[] = arr?.map((list) => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [list.longitude, list.latitude],
      },
      properties: {},
    };
  });

  const minPoints =
    Math.round(arr.length * 0.01) === 0 ? 1 : Math.round(arr.length * 0.01);

  const index = new Supercluster({
    minZoom: 0,
    maxZoom: 15,
    radius: 60,
    minPoints: minPoints,
  });

  index.load(result);

  const getCluster = index?.getClusters(
    [
      cameraLotation?.topLeftLon || 120,
      cameraLotation?.bottomRightLat || 20,
      cameraLotation?.bottomRightLon || 140,
      cameraLotation?.topLeftLat || 40,
    ],
    zoomLevel - 2,
  );

  return getCluster;
};

export default supercluster;
