import { RedirectLocationData } from "@components/molecules/RedirectNavigationBottomSheet";
import DeviceTypeCheck from "./device-check.util";

type LabelTypes = "티맵" | "카카오맵" | "네이버지도";

export interface RedirectNavigateAppProps {
  label: LabelTypes;
  locationData: RedirectLocationData;
}

export const redirectNavigateApp = ({
  label,
  locationData,
}: RedirectNavigateAppProps) => {
  const {
    currentAddress,
    currentLatitude,
    currentLongitude,
    currentStationName,
    targetLatitude,
    targetLongitude,
  } = locationData;

  let url: string = "";
  try {
    switch (label) {
      case "티맵":
        url = `tmap://route?goalname=${currentStationName}&goalx=${targetLongitude}&goaly=${targetLatitude}`;
        break;
      case "카카오맵":
        url = `kakaomap://route?sp=${currentLatitude},${currentLongitude}&ep=${targetLatitude},${targetLongitude}&by=CAR`;
        break;
      case "네이버지도":
        url = `nmap://route/car?slat=${currentLatitude}&slng=${currentLongitude}&sname=${"현재위치"}&dlat=${targetLatitude}&dlng=${targetLongitude}&dname=${currentAddress}&appname=everon.charger.service.dev`;
    }

    window.location.href = url;
  } catch (error) {
    console.error("REDIRECT APP >>>>> 📕", error);
  }

  setTimeout(function () {
    const storeURL = getStoreURL(label);
    if (storeURL && window.confirm("스토어로 이동하시겠습니까?")) {
      location.href = storeURL;
    }
  }, 800);
};

function getStoreURL(label: LabelTypes) {
  switch (label) {
    case "티맵": {
      return DeviceTypeCheck() === "android"
        ? "https://play.google.com/store/apps/details?id=com.skt.tmap.ku"
        : "https://apps.apple.com/kr/app/tmap-%EB%8C%80%EB%A6%AC-%EC%A3%BC%EC%B0%A8-%EC%A0%84%EA%B8%B0%EC%B0%A8%EC%B6%A9%EC%A0%84-%ED%82%A5%EB%B3%B4%EB%93%9C%EB%A5%BC-%ED%8B%B0%EB%A7%B5%EC%97%90%EC%84%9C/id431589174";
    }
    case "카카오맵": {
      return DeviceTypeCheck() === "android"
        ? "https://play.google.com/store/apps/details?id=net.daum.android.map"
        : "https://apps.apple.com/kr/app/%EC%B9%B4%EC%B9%B4%EC%98%A4%EB%A7%B5-%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD-no-1-%EC%A7%80%EB%8F%84%EC%95%B1/id304608425";
    }
    case "네이버지도": {
      return DeviceTypeCheck() === "android"
        ? "https://play.google.com/store/apps/details?id=com.nhn.android.nmap"
        : "https://apps.apple.com/kr/app/naver-map-navigation/id311867728";
    }
    default: {
      return undefined;
    }
  }
}
