import { StationBrandCode } from "@apis/map";
import { ReactComponent as Ba } from "@assets/icons/brand/ba.svg";
import { ReactComponent as Cu } from "@assets/icons/brand/cu.svg";
import { ReactComponent as Cv } from "@assets/icons/brand/cv.svg";
import { ReactComponent as Ecp } from "@assets/icons/brand/ecp.svg";
import { ReactComponent as Ev } from "@assets/icons/brand/ev.svg";
import { ReactComponent as Ez } from "@assets/icons/brand/ez.svg";
import { ReactComponent as Ec } from "@assets/icons/brand/ec.svg";
import { ReactComponent as Gn } from "@assets/icons/brand/gn.svg";
import { ReactComponent as Gp } from "@assets/icons/brand/gp.svg";
import { ReactComponent as He } from "@assets/icons/brand/he.svg";
import { ReactComponent as Hm } from "@assets/icons/brand/hm.svg";
import { ReactComponent as Ik } from "@assets/icons/brand/ik.svg";
import { ReactComponent as Ja } from "@assets/icons/brand/ja.svg";
import { ReactComponent as Jd } from "@assets/icons/brand/jd.svg";
import { ReactComponent as Je } from "@assets/icons/brand/je.svg";
import { ReactComponent as Jec } from "@assets/icons/brand/jec.svg";
import { ReactComponent as Jj } from "@assets/icons/brand/jj.svg";
import { ReactComponent as Ju } from "@assets/icons/brand/ju.svg";
import { ReactComponent as Ke } from "@assets/icons/brand/ke.svg";
import { ReactComponent as Kep } from "@assets/icons/brand/kep.svg";
import { ReactComponent as Kl } from "@assets/icons/brand/kl.svg";
import { ReactComponent as Kn } from "@assets/icons/brand/kn.svg";
import { ReactComponent as Kp } from "@assets/icons/brand/kp.svg";
import { ReactComponent as Lh } from "@assets/icons/brand/lh.svg";
import { ReactComponent as Lu } from "@assets/icons/brand/lu.svg";
import { ReactComponent as Me } from "@assets/icons/brand/me.svg";
import { ReactComponent as Mo } from "@assets/icons/brand/mo.svg";
import { ReactComponent as Nt } from "@assets/icons/brand/nt.svg";
import { ReactComponent as Pi } from "@assets/icons/brand/pi.svg";
import { ReactComponent as Pw } from "@assets/icons/brand/pw.svg";
import { ReactComponent as Sb } from "@assets/icons/brand/sb.svg";
import { ReactComponent as Sc } from "@assets/icons/brand/sc.svg";
import { ReactComponent as Sf } from "@assets/icons/brand/sf.svg";
import { ReactComponent as Sk } from "@assets/icons/brand/sk.svg";
import { ReactComponent as Skr } from "@assets/icons/brand/skr.svg";
import { ReactComponent as Ss } from "@assets/icons/brand/ss.svg";
import { ReactComponent as St } from "@assets/icons/brand/st.svg";
import { ReactComponent as Tb } from "@assets/icons/brand/tb.svg";
import { ReactComponent as Td } from "@assets/icons/brand/td.svg";
import { ReactComponent as Us } from "@assets/icons/brand/us.svg";
import { ReactComponent as Re } from "@assets/icons/brand/re.svg";
import { ReactComponent as Pl } from "@assets/icons/brand/pl.svg";
import { ReactComponent as Pc } from "@assets/icons/brand/pc.svg";
import { ReactComponent as Sd } from "@assets/icons/brand/sd.svg";
import { ReactComponent as Dl } from "@assets/icons/brand/dl.svg";
import { ReactComponent as In } from "@assets/icons/brand/in.svg";
import { ReactComponent as Epit } from "@assets/icons/brand/hyundai.svg";


import React from "react";

interface BrandLogoRenderProps {
  stationBrand: StationBrandCode;
}

const BrandLogoRender: React.FC<BrandLogoRenderProps> = ({
  stationBrand = "에버온",
}) => {
  switch (stationBrand) {
    case StationBrandCode.한국전기차충전서비스:
      return <He />;
    case StationBrandCode.차지비:
      return <Pi />;
    case StationBrandCode.파워큐브:
      return <Pw />;
    case StationBrandCode.환경부:
      return <Me />;
    case StationBrandCode.한국환경공단:
      return <Kn />;
    case StationBrandCode.에스트래픽:
      return <St />;
    case StationBrandCode.대영채비:
      return <Cv />;
    case StationBrandCode.제주도청:
      return <Jd />;
    case StationBrandCode.에버온:
      return <Ev />;
    case StationBrandCode.지에스커넥트:
      return <Gn />;
    case StationBrandCode.전주시:
      return <Jj />;
    case StationBrandCode.클린일렉스:
      return <Kl />;
    case StationBrandCode.제주전기자동차서비스:
      return <Je />;
    case StationBrandCode.한국전력:
      return <Kp />;
    case StationBrandCode.한국전력공사:
      return <Kep />;
    case StationBrandCode.이카플러그:
      return <Ecp />;
    case StationBrandCode.에스케이렌터카주식회사:
      return <Skr />;
    case StationBrandCode.휴맥스이브이:
      return <Hm />;
    case StationBrandCode.정읍시:
      return <Ju />;
    case StationBrandCode.익산시:
      return <Ik />;
    case StationBrandCode.차지인:
      return <Ez />;
    case StationBrandCode.매니지온:
      return <Mo />;
    case StationBrandCode.군포시:
      return <Gp />;
    case StationBrandCode.삼척시:
      return <Sc />;
    case StationBrandCode.소프트베리:
      return <Sb />;
    case StationBrandCode.나이스차저:
      return <Nt />;
    case StationBrandCode.울산시:
      return <Us />;
    case StationBrandCode.스타코프:
      return <Sf />;
    case StationBrandCode.삼성EVC:
      return <Ss />;
    case StationBrandCode.타디스테크놀로지:
      return <Td />;
    case StationBrandCode.LG헬로비전:
      return <Lh />;
    case StationBrandCode.LG유플러스:
      return <Lu />;
    case StationBrandCode.한국전기차인프라기술:
      return <Ke />;
    case StationBrandCode.씨어스:
      return <Cu />;
    case StationBrandCode.부안군:
      return <Ba />;
    case StationBrandCode.태백시:
      return <Tb />;
    case StationBrandCode.중앙제어:
      return <Ja />;
    case StationBrandCode.제주에너지공사:
      return <Jec />;
    case StationBrandCode.에스케이에너지:
      return <Sk />;
    case StationBrandCode.이지차저:
      return <Ec />;
    case StationBrandCode.플러그링크:
      return <Pl />;
    case StationBrandCode.레드이엔지:
      return <Re />;
    case StationBrandCode.파킹클라우드:
      return <Pc />;
    case StationBrandCode.스칼라데이터:
      return <Sd />;
    case StationBrandCode.딜라이브:
      return <Dl />;
    case StationBrandCode.신세계아이앤씨:
      return <In />;
    case StationBrandCode.현대자동차:
      return <Epit />;
    default:
      return <Ev />;
  }
};

export default BrandLogoRender;
