import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
    ${reset}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body{
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    background: white;
    height: 100%;
    font-size: 16px;
}

input {
    outline: none;
}

button { 
    padding: 0;
    border: none;
}
`;

export default GlobalStyles;
