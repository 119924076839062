import { ResponseCustomerStation } from "@apis/map";

export function findDataDifferences(
  initialData: ResponseCustomerStation[],
  updatedData: ResponseCustomerStation[],
) {
  const initialDataIds = new Set(
    initialData.map((item) => item.stations[0].stationId),
  );
  const addedData = updatedData.filter(
    (item) => !initialDataIds.has(item.stations[0].stationId),
  );
  const removedData = initialData.filter(
    (item) => !initialDataIds.has(item.stations[0].stationId),
  );
  return { addedData, removedData };
}

// NOTE : 전화 걸기
export function phoneCall(phoneNumber: string) {
  location.href = "tel:" + phoneNumber;
}

// NOTE : 중간에 해당 되는 값인지 체크 하는 함수
export const checkMiddle = (
  target: number,
  smallNum: number,
  biggerNum: number,
): boolean => {
  return target < biggerNum && target > smallNum;
};
