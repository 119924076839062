import KAKAO from "@assets/icons/kakao-navi.svg";
import TMAP from "@assets/icons/tmap.svg";
import NAVER from "@assets/icons/naver-map.svg";
import CURRENT from "@assets/icons/markers/current.svg";
import AVAILABLE from "@assets/icons/markers/available.svg";
import UN_AVAILABLE from "@assets/icons/markers/un-available.svg";
import USING from "@assets/icons/markers/using.svg";

export const ICONS = {
  MAP: {
    KAKAO,
    TMAP,
    NAVER,
  },

  MARKER: {
    CURRENT,
    AVAILABLE,
    UN_AVAILABLE,
    USING,
  },
};
