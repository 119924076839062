import { COLORS, TYPO, Z_INDEX } from "@styles/index";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

interface SearchProps extends PropsWithChildren {
  hasBackButton?: boolean;
  onBackButtonClick?: () => void;
  hasCloseButton?: boolean;
  onCloseButtonClick?: () => void;
  headerTitle?: string;
  disableHeader?: boolean;
  headerContentsPosition?: string;
}

const Layout: React.FC<SearchProps> = ({
  hasBackButton,
  onBackButtonClick,
  hasCloseButton,
  onCloseButtonClick,
  headerTitle = "전기차 충전소",
  children,
  disableHeader = false,
  headerContentsPosition,
}) => {
  return (
    <Container>
      {!disableHeader && (
        <HeaderContainer $headerContentsPosition={headerContentsPosition}>
          {hasBackButton && (
            <BackButton onClick={onBackButtonClick}>
              <ArrowLeftIcon />
            </BackButton>
          )}

          <h1>{headerTitle}</h1>

          {hasCloseButton && (
            <CloseButton onClick={onCloseButtonClick}>
              <CloseIcon />
            </CloseButton>
          )}
        </HeaderContainer>
      )}

      {children}
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const HeaderContainer = styled.div<{ $headerContentsPosition?: string }>`
  position: ${({ $headerContentsPosition }) =>
    $headerContentsPosition || "absolute"};
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  z-index: ${Z_INDEX.HEADER};
  background: ${COLORS.WHITE};

  > h1 {
    ${TYPO.HEADING_6}
  }
`;

const BackButton = styled.button`
  position: absolute;
  left: 16px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
`;
