import { Button } from "@components/atoms/Button";
import { ToastPopup } from "@components/atoms/ToastPopup";
import { useState } from "react";
import { styled } from "styled-components";

interface NavigationButtonLenderProps {
  onNavigateButtonClick: () => void;
  copyText: string;
  style?: React.CSSProperties;
}
export const NavigationButtonLender = ({
  onNavigateButtonClick,
  copyText,
  style,
}: NavigationButtonLenderProps) => {
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);

  const handleAddressCopyButtonClick = () => {
    window.navigator.clipboard.writeText(copyText).then(() => {
      setIsToastPopupOpen(true);
    });
  };

  return (
    <>
      <ButtonArea style={style}>
        <Button
          btnType="fill"
          style={{ flex: 2 }}
          onClick={onNavigateButtonClick}
        >
          길 안내 시작
        </Button>
        <Button
          btnType="outline"
          style={{ flex: 1 }}
          onClick={handleAddressCopyButtonClick}
        >
          복사하기
        </Button>
      </ButtonArea>
      <ToastPopup
        isOpen={isToastPopupOpen}
        setOpen={setIsToastPopupOpen}
      ></ToastPopup>
    </>
  );
};

const ButtonArea = styled.div`
  display: flex;
  padding: 0 20px 20px;
  gap: 8px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
`;
