import { IMAGES } from "@assets/images";
import { Button } from "@components/atoms/Button";
import Layout from "@components/templates/Layout";
import { TYPO } from "@styles/index";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

interface ErrorProps {}

const Error: React.FC<ErrorProps> = ({}) => {
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate("/");
  };

  return (
    <Layout
      headerTitle=""
      hasBackButton
      onBackButtonClick={handleBackButtonClick}
    >
      <Container>
        <ErrorContainer>
          <ErrorImage />

          <TextContainer>
            <h1>Network Error</h1>
            <h2>오류가 발생하였습니다.</h2>
          </TextContainer>

          <Button btnType="fill" onClick={handleBackButtonClick}>
            다시 불러오기
          </Button>
        </ErrorContainer>
      </Container>
    </Layout>
  );
};

export default Error;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const ErrorImage = styled.span`
  background: no-repeat center/cover url(${IMAGES.ERROR_IMAGE});
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  > h2 {
    ${TYPO.BODY_7};
    font-weight: 400;
  }
`;
