import useGlobalModalStore, {
  GlobalModalType,
} from "@store/useGlobalModalStore";

export default function useGlobalModal() {
  const setModal = useGlobalModalStore((state) => state.setGlobalModal);

  const showModal = ({ modalType, modalProps }: GlobalModalType) => {
    setModal({ modalType, modalProps });
  };
  const hideModal = () => {
    setModal({ modalType: null, modalProps: null });
    window.document.body.style.overflow = "unset";
  };

  const showLoadingModal = () => {
    return showModal({
      modalType: "LoadingModal",
      modalProps: {},
    });
  };

  return {
    showModal,
    hideModal,
    showLoadingModal,
  };
}
