import { StationStatus } from "@apis/map";
import { IMAGES } from "@assets/images";
import "@constants/animation.css";
import { randomNumberGenerator } from "@utils/number-util";

export interface ChargerCountDataType {
  usableFastChargerCount: number;
  usableLateChargerCount: number;
}

// 충전소 마커 렌더링
export const chargerStationMarkerLender = (
  status: StationStatus,
  chargerCountData: ChargerCountDataType,
  isSelected = false,
  isAnimationPlay = true,
) => {
  const { usableFastChargerCount, usableLateChargerCount } = chargerCountData;
  const chargerCount = `완 ${usableLateChargerCount ?? 0}/급 ${
    usableFastChargerCount ?? 0
  }`;
  const isAnimation = isAnimationPlay
    ? `animation: markerAnimation ${randomNumberGenerator(
        0.5,
        0.2,
      )}s ease-in-out `
    : "";

  const isSelectedMarkerSize = isSelected
    ? "min-width: 41px; min-height: 55px;"
    : "min-width: 36px; min-height: 48px;";

  const canUseMarker = `<div 
    style="
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: 70px;
    height: 86px;
    ${isAnimation};
    ${isSelected && "z-index: 1"}
  "

  >
  <div
    id="canUseMarker"
    style="
      background: #00b8ff;
      width: auto;
      max-width: 70px;
      height: 20px;
      border-radius: 100px;
      color: white;
      font-size: 11px;
      font-weight: 700;
      line-height: 16px;
      padding: 2px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      vertical-align: middle;
    "
  >
    ${chargerCount}
  </div>
  <div
    style="
      background: no-repeat center/cover url(${
        isSelected ? IMAGES.MARKER.SELECTED_AVAILABLE : IMAGES.MARKER.AVAILABLE
      });
     ${isSelectedMarkerSize};
    "
  />
  </div>`;

  const unAvailableMarker = `  <div
  style="
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: 70px;
    height: 86px;
    ${isAnimation};
    ${isSelected && "z-index: 1"}
  "
>
  <div
    id="unAvailable"
    style="
      background: #cfd6d9;
      width: fit-content;
      height: 20px;
      border-radius: 100px;
      color: white;
      font-size: 11px;
      font-weight: 700;
      line-height: 16px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      vertical-align: middle;
    "
  >
    점검중
  </div>
  <div
    style="
      background: no-repeat center/cover url(${
        isSelected
          ? IMAGES.MARKER.SELECTED_UN_AVAILABLE
          : IMAGES.MARKER.UN_AVAILABLE
      });
      ${isSelectedMarkerSize};
    "
  ></div>
</div>`;

  const usingMarker = ` <div
  style="
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: 70px;
    height: 86px;
    ${isAnimation};
    ${isSelected && "z-index: 1"}
  "
>
  <div
    id="unAvailable"
    style="
      background: #6e777c;
      width: fit-content;
      height: 20px;
      border-radius: 100px;
      color: white;
      font-size: 11px;
      font-weight: 700;
      line-height: 16px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      vertical-align: middle;
    "
  >
    충전중
  </div>
  <div
    style="
      background: no-repeat center/cover  url(${
        isSelected ? IMAGES.MARKER.SELECTED_USING : IMAGES.MARKER.USING
      });
      ${isSelectedMarkerSize};
    "
  ></div>
</div>`;

  switch (status) {
    case StationStatus.CanUse:
      return canUseMarker;
    case StationStatus.InUse:
      return usingMarker;
    case StationStatus.Maintenance:
      return unAvailableMarker;
  }
};
