import GlobalModal from "@components/molecules/Modal/GlobalModal";
import useGlobalModal from "@hooks/useGlobalModal";
import ChargerDetail from "@pages/ChargerDetail";
import Error from "@pages/Error";
import Map from "@pages/Map";
import Search from "@pages/Search";
import SearchAddress from "@pages/SearchAddress";
import useLocationStore from "@store/useLocationStore";
import GlobalStyles from "@styles/GlobalStyles";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const {
    isFirstLocationAccess,
    globalCurrentLatitude,
    globalCurrentLongitude,
    setGlobalCurrentLocation,
    setIsFirstLocationAccess,
  } = useLocationStore();
  const { showLoadingModal, hideModal } = useGlobalModal();
  const htmlDom = document.querySelector("html");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.pathname === "/") {
      htmlDom?.style.setProperty("overflow", "hidden");
    } else {
      htmlDom?.style.setProperty("overflow", "unset");
    }
  }, [location]);

  useEffect(() => {
    if (!isFirstLocationAccess) {
      showLoadingModal();
    } else {
      hideModal();
    }
  }, [isFirstLocationAccess]);

  useEffect(() => {
    if (!globalCurrentLatitude || !globalCurrentLongitude) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          setGlobalCurrentLocation(lat, lng);

          // NOTE : 처음 현재 위치 접근 했는지 여부
          if (!isFirstLocationAccess) setIsFirstLocationAccess(true);
        },
        (error) => {
          if (!isFirstLocationAccess) setIsFirstLocationAccess(true);
          console.error("Error getting location:", error);
        },
      );
    }
  }, [
    globalCurrentLatitude,
    globalCurrentLongitude,
    isFirstLocationAccess,
    setGlobalCurrentLocation,
    setIsFirstLocationAccess,
  ]);

  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path={"/"} element={<Map />} />
        <Route path={"/search"} element={<Search />} />
        <Route path={"/search/address"} element={<SearchAddress />} />
        <Route path={"/detail"} element={<ChargerDetail />} />
        <Route path={"*"} element={<Error />} />
      </Routes>
      <GlobalModal />
    </>
  );
}

export default App;
