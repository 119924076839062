import ERROR_IMAGE from "./network-error.png";
import CLUSTER from "./cluster.png";
import AVAILABLE from "./markers/available.png";
import UN_AVAILABLE from "./markers/un-available.png";
import USING from "./markers/using.png";
import SELECTED_AVAILABLE from "./markers/selected-available.png";
import SELECTED_USING from "./markers/selected-using.png";
import SELECTED_UN_AVAILABLE from "./markers/selected-un-available.png";

export const IMAGES = {
  ERROR_IMAGE,
  CLUSTER,

  MARKER: {
    AVAILABLE,
    USING,
    UN_AVAILABLE,
    SELECTED_AVAILABLE,
    SELECTED_USING,
    SELECTED_UN_AVAILABLE,
  },
};
