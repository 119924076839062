import { ReactComponent as ToastIcon } from "@assets/icons/info-fill.svg";
import { COLORS, TYPO } from "@styles/index";
import { useEffect, useState } from "react";
import { css, styled } from "styled-components";

interface ToastPopupProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  text?: string;
}

export const ToastPopup = ({
  setOpen,
  isOpen,
  text = "주소가 복사되었습니다.",
}: ToastPopupProps) => {
  const [isAnimationStart, setIsAnimationStart] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!isAnimationStart && isOpen) setIsAnimationStart(true);
    }, 200);

    setTimeout(() => {
      if (isOpen) {
        setIsAnimationStart(false);
      }
    }, 2000);

    setTimeout(() => {
      if (isOpen) {
        setOpen(false);
      }
    }, 2500);
  }, [isOpen]);

  return (
    <ToastPopupContainer $isOpen={isOpen} $isAnimationStart={isAnimationStart}>
      <ToastIcon />
      <p>{text}</p>
    </ToastPopupContainer>
  );
};

const ToastPopupContainer = styled.div<{
  $isOpen: boolean;
  $isAnimationStart: boolean;
}>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: ${COLORS.GRAY2};
  color: ${COLORS.WHITE};
  ${TYPO.BODY_8};
  z-index: 100;
  width: 90vw;
  transition: all 0.5s ease-in-out;
  bottom: -49px;
  opacity: 0;
  display: none;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `};

  ${({ $isAnimationStart }) =>
    $isAnimationStart
      ? css`
          bottom: 25px;
          opacity: 1;
        `
      : css`
          bottom: -49px;
          opacity: 0;
        `};
`;
