import React, { PropsWithChildren } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

interface BaseModalProps extends PropsWithChildren {
  isOpen: boolean;
  handleClose?: () => void;
  modalWidth?: number;
  disableModalPadding?: boolean;
  disableModalInnerGap?: boolean;
  contentsStyle?: React.CSSProperties;
}

const BaseModal: React.FC<BaseModalProps> = ({
  children,
  isOpen,
  modalWidth,
  disableModalPadding,
  disableModalInnerGap,
  contentsStyle,
  handleClose,
}) => {
  const customStyles = {
    overlay: {
      background: "#00000040",
      zIndex: 9999,
    },
    content: contentsStyle,
  };
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      modalWidth={modalWidth}
      disableModalPadding={disableModalPadding}
      disableModalInnerGap={disableModalInnerGap}
      ariaHideApp={false}
    >
      {children}
    </StyledModal>
  );
};

export default BaseModal;

const StyledModal = styled(ReactModal)<{
  modalWidth?: number;
  disableModalPadding?: boolean;
  disableModalInnerGap?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  outline: none;
`;
