import { useCountUp } from "@hooks/useCountUp";
import { FunctionComponent } from "react";
import { Marker } from "react-naver-maps";
import Supercluster from "supercluster";

export interface ClusterProps {
  clusterData:
    | Supercluster.ClusterFeature<Supercluster.AnyProps>
    | Supercluster.PointFeature<Supercluster.AnyProps>;
  onClick?: () => void;
}

const CustomCluster: FunctionComponent<ClusterProps> = function CustomCluster({
  clusterData,
  onClick,
}) {
  const size = Math.max(
    100,
    Math.min(180, Math.log(clusterData?.properties?.point_count) * 15),
  );

  const textSize = () => {
    const point = clusterData.properties.point_count;
    switch (true) {
      case point < 10:
        return 18;
      case point < 100:
        return 20;
      case point < 1000:
        return 22;
      case point < 10000:
        return 24;
      default:
        return 22;
    }
  };

  const markerCount = useCountUp(clusterData?.properties?.point_count, 0, 500);

  return (
    <Marker
      position={{
        lat: clusterData?.geometry.coordinates[1],
        lng: clusterData?.geometry.coordinates[0],
      }}
      icon={{
        content: `<div style="cursor: pointer; display: flex; align-items: center; justify-content: center; width: ${size}px; height: ${size}px; font-size: ${textSize()}px; font-weight: 700; line-height: 30px; color: white; text-align: center; background-color: rgba(0, 184, 255, 0.80); border-radius: 100px; border: 1px solid #0099DB;">${
          markerCount.toString() || "0"
        }</div>`,
        anchor: { x: 0.5, y: 0.5 },
        size: { width: size, height: size },
      }}
      onClick={onClick}
    />
  );
};

export default CustomCluster;
