import { Charger } from "@apis/map";
import {
  ChargerStateType,
  ChargerStatusLender,
} from "@components/atoms/ChargerStatusLender";
import { COLORS, SHADOW, TYPO } from "@styles/index";
import { styled } from "styled-components";

interface ChargerDetailInfoCardProps {
  chargerData: Charger;
}
export const ChargerDetailInfoCard = ({
  chargerData,
}: ChargerDetailInfoCardProps) => {
  const {
    status,
    usedAt,
    simpleCode,
    chargerNickname,
    chargerTypeDescription,
    chargerCapacity,
  } = chargerData;
  const chargerBrandCheck = chargerData.cpoId === "EV";

  const chargerCode = chargerBrandCheck ? "간편코드" : "충전기 ID";

  return (
    <ChargerDetailInfoCardContainer>
      <ChargerNumberArea $status={status}>
        {chargerBrandCheck && (
          <span className="chargerCountNumber">{chargerNickname}</span>
        )}
        <ChargerCodeContainer>
          <span className="codeLabel">{chargerCode || "-"}</span>
          <span className="codeNumber">{simpleCode || "-"}</span>
        </ChargerCodeContainer>
      </ChargerNumberArea>

      <ChargerInfoArea>
        <ChargerTypeContainer>
          <span>유형</span>
          <span className="chargerType">
            {chargerTypeDescription || ""} ({chargerCapacity || 0}kW)
          </span>
        </ChargerTypeContainer>

        <ChargerStatusContainer>
          <span className="chargerStatusLabel">충전기 상태</span>

          <div className="chargerStatusArea">
            <small className="time">{usedAt}</small>
            <ChargerStatusLender status={status} />
          </div>
        </ChargerStatusContainer>
      </ChargerInfoArea>
    </ChargerDetailInfoCardContainer>
  );
};

const ChargerDetailInfoCardContainer = styled.div`
  background: ${COLORS.WHITE};
  padding: 18px 20px;
  border-radius: 12px;
  ${SHADOW.DP_4}
`;

const ChargerNumberArea = styled.div<{ $status?: ChargerStateType }>`
  border-bottom: 1px solid ${COLORS.GRAY10};
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  align-items: center;

  .chargerCountNumber {
    background: ${({ $status }) =>
      $status === "canUse" ? COLORS.PRIMARY : COLORS.GRAY9};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ $status }) =>
      $status === "canUse" ? COLORS.WHITE : COLORS.GRAY6};
    ${TYPO.HEADING_8};
  }
`;

const ChargerCodeContainer = styled.div`
  display: flex;
  flex-direction: column;

  .codeLabel {
    ${TYPO.BODY_10};
    color: ${COLORS.GRAY6};
  }

  .codeNumber {
    ${TYPO.HEADING_8};
    color: ${COLORS.GRAY2};
  }
`;

const ChargerInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ChargerTypeContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;

  > span {
    ${TYPO.BODY_8};
    color: ${COLORS.GRAY2};
  }

  .chargerType {
    font-weight: 400;
  }
`;

const ChargerStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  .chargerStatusArea {
    display: flex;
    align-items: center;
    gap: 4px;

    .time {
      ${TYPO.BODY_10};
      font-weight: 400;
      color: ${COLORS.GRAY6};
    }
  }
`;
