import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import { useEffect } from "react";
import LoadingModal from "../LoadingModal";
import useGlobalModalStore from "@store/useGlobalModalStore";

const MODAL_TYPES = {
  LoadingModal: "LoadingModal",
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.LoadingModal]: LoadingModal,
};

const GlobalModal: React.FC = () => {
  const {
    globalModal: { modalProps, modalType },
  } = useGlobalModalStore();

  const { lockScroll, openScroll } = useBodyScrollLock();
  const isScroll = document.body.style.overflow;

  useEffect(() => {
    if (isScroll === "hidden") return;
    if (modalType) {
      lockScroll();
    } else {
      openScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    const ModalComponent = MODAL_COMPONENTS[modalType];
    return <ModalComponent {...modalProps} />;
  };

  return <>{renderComponent()}</>;
};

export default GlobalModal;
