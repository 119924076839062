import { persist } from "zustand/middleware";
import { create } from "zustand";

export type LocationType = number | null;
interface IUseLocationStoreProps {
  // 검색 여부
  isSearch: boolean;

  // 검색 여부 설정
  setIsSearch: (isSearch: boolean) => void;

  // 검색 충전소 여부
  isSearchChargerStation: boolean;

  // 검색 충전소 여부 설정
  setIsSearchChargerStation: (isSearchChargerStation: boolean) => void;

  // 검색 위치
  globalSearchLatitude: LocationType;
  globalSearchLongitude: LocationType;

  // 검색 위치 설정
  setSearchLocation: (
    globalSearchLatitude: number,
    globalSearchLongitude: number,
  ) => void;

  resetSearchLocation: () => void;

  // 처음 접근하는지 여부
  isFirstLocationAccess: boolean;

  // 처음 접근 여부 설정
  setIsFirstLocationAccess: (isFirstLocationAccess: boolean) => void;

  // 현재 위치
  globalCurrentLatitude: LocationType;
  globalCurrentLongitude: LocationType;

  // 현재 위치 설정
  setGlobalCurrentLocation: (
    globalCurrentLatitude: number,
    globalCurrentLongitude: number,
  ) => void;

  // 충전소 상세 들어갔는지 여부 체크
  isDetailChargerStation: boolean;

  // 충전소 상세 들어갔는지 여부 체크 설정
  setIsDetailChargerStation: (isDetailChargerStation: boolean) => void;

  // 충전소 상세 위치
  globalDetailLatitude: LocationType;
  globalDetailLongitude: LocationType;

  // 충전소 상세 위치 설정
  setGlobalDetailLocation: (
    globalDetailLatitude: number,
    globalDetailLongitude: number,
  ) => void;
}

const initialData = {
  isFirstLocationAccess: false,
  isSearch: false,
  isSearchChargerStation: false,
  isDetailChargerStation: false,

  globalCurrentLatitude: null,
  globalCurrentLongitude: null,

  globalSearchLatitude: null,
  globalSearchLongitude: null,

  globalDetailLatitude: null,
  globalDetailLongitude: null,
};

const useLocationStore = create(
  persist<IUseLocationStoreProps>(
    (set) => ({
      ...initialData,
      setIsSearch: (isSearch: boolean) => set(() => ({ isSearch })),
      setIsSearchChargerStation: (isSearchChargerStation: boolean) =>
        set(() => ({ isSearchChargerStation })),
      setSearchLocation: (
        globalSearchLatitude: number,
        globalSearchLongitude: number,
      ) =>
        set(() => ({
          globalSearchLatitude,
          globalSearchLongitude,
        })),
      resetSearchLocation: () =>
        set(() => ({
          globalSearchLatitude: null,
          globalSearchLongitude: null,
        })),
      setIsFirstLocationAccess: (isFirstLocationAccess: boolean) =>
        set(() => ({ isFirstLocationAccess })),
      setGlobalCurrentLocation: (
        globalCurrentLatitude: number,
        globalCurrentLongitude: number,
      ) =>
        set(() => ({
          globalCurrentLatitude,
          globalCurrentLongitude,
        })),
      setIsDetailChargerStation: (isDetailChargerStation: boolean) =>
        set(() => ({ isDetailChargerStation })),
      setGlobalDetailLocation: (
        globalDetailLatitude: number,
        globalDetailLongitude: number,
      ) =>
        set(() => ({
          globalDetailLatitude,
          globalDetailLongitude,
        })),
    }),
    {
      name: "location-storage",
    },
  ),
);

export default useLocationStore;
