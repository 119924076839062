import { ResponseCustomerStation, Station, requestStation } from "@apis/map";
import { ReactComponent as Charger } from "@assets/icons/charger.svg";
import { ReactComponent as CurrentLocation } from "@assets/icons/current-location.svg";
import { ReactComponent as List } from "@assets/icons/list.svg";
import { ReactComponent as Refresh } from "@assets/icons/refresh.svg";
import AnimationBottomSheet from "@components/atoms/AnimationBottomSheet";
import { chargerStationMarkerLender } from "@components/atoms/ChargerMarkerRender";
import { EmptyPlaceholder } from "@components/atoms/EmptyPlaceholder";
import IconButton from "@components/atoms/IconButton";
import { ToastPopup } from "@components/atoms/ToastPopup";
import {
  ChargerCard,
  ChargerCardArea,
} from "@components/molecules/ChargerCard";
import CustomCluster from "@components/molecules/CustomCluster";
import { DetailChargerCard } from "@components/molecules/DetailChargerCard";
import Layout from "@components/templates/Layout";
import { CURRENT_LOCATION_MARKER } from "@constants/string";
import useWindowSize from "@hooks/useWindowSize";
import { ErrorType } from "@network/index";
import useChargerStationStore from "@store/useChargerStationStore";
import useLocationStore from "@store/useLocationStore";
import { COLORS, SHADOW, TYPO } from "@styles/index";
import { findDataDifferences } from "@utils/data-fn.utils";
import supercluster from "@utils/supercluster";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useNavermaps,
} from "react-naver-maps";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export interface StationDetail extends Station {
  distance: number;
  latitude: number;
  longitude: number;
}

interface VisibleStationDetailProps {
  visible: boolean;
  stationData: StationDetail[];
}

const Map: React.FC = () => {
  const navigate = useNavigate();
  const navermaps = useNavermaps();
  const { height } = useWindowSize();
  const [visibleList, setVisibleList] = useState(false);
  const [visibleStationDetail, setVisibleStationDetail] =
    useState<VisibleStationDetailProps>({
      visible: false,
      stationData: [],
    });
  const {
    isFirstLocationAccess,
    isDetailChargerStation,
    isSearchChargerStation,
    isSearch,
    globalDetailLatitude,
    globalDetailLongitude,
    globalSearchLatitude,
    globalSearchLongitude,
    globalCurrentLatitude,
    globalCurrentLongitude,
    setIsFirstLocationAccess,
    setIsSearch,
    setIsSearchChargerStation,
    setGlobalCurrentLocation,
    resetSearchLocation,
    setGlobalDetailLocation,
    setIsDetailChargerStation,
  } = useLocationStore();
  const { globalChargerStationList, setGlobalChargerStationList } =
    useChargerStationStore();
  const [mapInstance, setMapInstance] = useState<naver.maps.Map>();
  const [mapCenter, setMapCenter] = useState({
    lat: globalCurrentLatitude || 37.565772,
    lng: globalCurrentLongitude || 126.98872,
  });
  const [currentLocation, setCurrentLocation] = useState({
    lat: globalCurrentLatitude || 37.565772,
    lng: globalCurrentLongitude || 126.98872,
  });
  const [mapZoom, setZoom] = useState(17);
  const [beforeChargerStationData, setBeforeChargerStationData] = useState<
    ResponseCustomerStation[]
  >([]);
  const [addedChargerStationData, setAddedChargerStationData] = useState<
    ResponseCustomerStation[]
  >([]);
  const [selectedStationId, setSelectedStation] = useState<string>("");

  // 현재 좌표 기준으로 지도 영역을 계산하기 위해 state
  const [mapSearchLocation, setMapSearchLocation] = useState({
    topLeftLat: 0,
    topLeftLon: 0,
    bottomRightLat: 0,
    bottomRightLon: 0,
  });

  const [isLocationErrorPopupOpen, setIsLocationErrorPopupOpen] =
    useState(false);

  const mapSearchData = {
    topLeftLat: mapSearchLocation.topLeftLat,
    topLeftLon: mapSearchLocation.topLeftLon,
    bottomRightLat: mapSearchLocation.bottomRightLat,
    bottomRightLon: mapSearchLocation.bottomRightLon,
    curLatitude: currentLocation.lat,
    curLongitude: currentLocation.lng,
    sido: "",
    gugun: "",
    chargerBrand: null,
    chargerType: null,
    minChargeSpeed: 3,
    maxChargeSpeed: 350,
    freeParkingOnly: false,
    freeEntryOnly: false,
    allDayOpenOnly: false,
  };

  // NOTE : 충전소 데이터 가져오기
  const { data: chargerStationData, refetch: chargerStationDataRefetch } =
    useQuery(
      ["fetchStationData", mapSearchData],
      () => requestStation(mapSearchData),
      {
        retry: false,
        enabled:
          currentLocation.lat !== 0 &&
          currentLocation.lng !== 0 &&
          mapSearchLocation.bottomRightLat !== 0 &&
          mapSearchLocation.bottomRightLon !== 0 &&
          mapSearchLocation.topLeftLat !== 0 &&
          mapSearchLocation.topLeftLon !== 0,
        onSuccess: async (data) => {
          setBeforeChargerStationData(data);

          // NOTE : 추가된 데이터 찾기
          const { addedData } = findDataDifferences(
            beforeChargerStationData,
            data,
          );
          if (addedData.length > 0) {
            setAddedChargerStationData(addedData);
          }
        },
        keepPreviousData: true,
        onError: (error: ErrorType) => {
          if (error?.statusCode === 404) {
            setBeforeChargerStationData([]);
          }
        },
      },
    );

  // 현재 위치 구하기
  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          setCurrentLocation({ lat, lng });
          setMapCenter({ lat, lng });
          setGlobalCurrentLocation(lat, lng);

          // NOTE : 처음 현재 위치 접근 했는지 여부
          if (!isFirstLocationAccess) setIsFirstLocationAccess(true);
        },
        (error) => {
          if (!isFirstLocationAccess) setIsFirstLocationAccess(true);
          console.error("Error getting location:", error);
          setIsLocationErrorPopupOpen(true);
        },
      );
    } else {
      if (!isFirstLocationAccess) setIsFirstLocationAccess(true);
      alert("Geolocation is not supported by this browser.");
    }
  };

  // NOTE : 현재 위치가 변경되면, 지도 영역을 다시 계산해서 데이터를 가져온다.
  const handleSearchChargerButtonClick = () => {
    navigate("/search");
    setGlobalCurrentLocation(currentLocation.lat, currentLocation.lng);
  };

  // 새로고침 버튼
  const handleRefreshButtonClick = () => {
    chargerStationDataRefetch();
  };

  // 현재 위치 버튼
  const handleCurrentLocationButtonClick = () => {
    handleGetCurrentLocation();
    mapInstance?.setCenter(currentLocation);
    mapInstance?.setZoom(17);
  };

  // 목록 보기 버튼
  const handleChargerListButtonClick = () => {
    setVisibleList(!visibleList);
  };

  // 목록의 충전소 카드 클릭
  const handleChargerCardClick = (stationData: StationDetail) => {
    const { stationId, stationBrand, latitude, longitude } = stationData;

    if (latitude && longitude) {
      setGlobalDetailLocation(latitude, longitude);
    }

    setIsDetailChargerStation(true);
    setGlobalChargerStationList([stationData]);
    navigate(`/detail?stationId=${stationId}&stationBrand=${stationBrand}`);
  };

  // 마커 랜더링
  const markerRender = useCallback(
    (data: ResponseCustomerStation, idx: number) => {
      if (!mapInstance) return null;

      return (
        <Marker
          key={idx}
          icon={{
            content: chargerStationMarkerLender(
              data.status,
              {
                usableFastChargerCount: data.usableFastChargerTotalCount,
                usableLateChargerCount: data.usableLateChargerTotalCount,
              },
              selectedStationId === data.stations[0].stationId,
              addedChargerStationData.some(
                (station) =>
                  station.stations[0].stationId === data.stations[0].stationId,
              ),
            ),
            anchor: { x: 36, y: 86 },
          }}
          position={{
            lat: data.latitude,
            lng: data.longitude,
          }}
          onClick={() => {
            const zoom = mapInstance.getZoom();

            const lat = () => {
              switch (zoom) {
                case 16:
                  return data.latitude - 0.0025;
                case 17:
                  return data.latitude - 0.001;
                case 18:
                  return data.latitude - 0.0005;
                case 19:
                  return data.latitude - 0.00025;
                case 20:
                  return data.latitude - 0.0001;
                default:
                  return data.latitude - 0.0002;
              }
            };

            mapInstance.panTo({
              lat: lat(),
              lng: data.longitude,
            });

            const customStationData: StationDetail[] = data.stations.map(
              (station) => {
                return {
                  ...station,
                  distance: data.distance,
                  latitude: data.latitude,
                  longitude: data.longitude,
                };
              },
            );

            if (visibleList) {
              setVisibleList(false);
            }

            // 충전소 상세정보 바텀시트에 보여주기
            setVisibleStationDetail({
              stationData: customStationData,
              visible: true,
            });
            setGlobalCurrentLocation(currentLocation.lat, currentLocation.lng);
            setSelectedStation(data.stations[0].stationId);
          }}
        />
      );
    },
    [
      addedChargerStationData,
      currentLocation.lat,
      currentLocation.lng,
      mapInstance,
      selectedStationId,
      setGlobalCurrentLocation,
      visibleList,
    ],
  );

  // 클러스터링 마커 데이터
  const markerCluster = useCallback(() => {
    if (!mapInstance) return [];
    if (!chargerStationData) return [];
    return supercluster(chargerStationData, mapInstance?.getZoom(), {
      topLeftLat: mapSearchLocation.topLeftLat,
      bottomRightLat: mapSearchLocation.bottomRightLat,
      bottomRightLon: mapSearchLocation.bottomRightLat,
      topLeftLon: mapSearchLocation.topLeftLon,
    });
  }, [
    chargerStationData,
    mapInstance,
    mapSearchLocation.bottomRightLat,
    mapSearchLocation.topLeftLat,
    mapSearchLocation.topLeftLon,
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  // NOTE : 처음 접속시, url에서 위도와 경도 기준으로 포커스
  useEffect(() => {
    if (!isFirstLocationAccess) setIsFirstLocationAccess(true);


    if (searchParams) {
      const latitude = searchParams.get("latitude");
      const longitude = searchParams.get("longitude");
      const dcchacod = searchParams.get("dcchacod");

      type ChargerType = "CHRA02" | "CHRA07" | "CHRA04" | "CHRA01";
      const checkChargerType = (dcchacod: ChargerType) => {
        switch (dcchacod) {
          case "CHRA02":
            return "완속";

          case "CHRA07":
            return "AC3상(급속)";

          case "CHRA04":
            return "DC콤보(급속)";

          case "CHRA01":
            return "DC차데모(급속)";

          default:
            return "DC콤보(급속)";
        }
      };

      if (!dcchacod) return;
      if (!latitude || !longitude) return;

      setGlobalCurrentLocation(Number(latitude), Number(longitude));
      // setCurrentLocation({ lat: Number(latitude), lng: Number(longitude) });
      setMapCenter({
        lat: Number(latitude),
        lng: Number(longitude),
      });

      const dcchacodArr = dcchacod.split(",") as ChargerType[];
      setTimeout(() => {
        alert(
          `지금 사용중인 자동차의 충전 타입은 [${dcchacodArr.map(
            (chargerType) => checkChargerType(chargerType),
          )}] 입니다.`,
        );
      }, 1000);
      setSearchParams();
    }
  }, []);

  // NOTE : 실시간 현재 위치 갱신
  useEffect(() => {
    if (!isFirstLocationAccess) return;
    const watchCurrent = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        setCurrentLocation({ lat, lng });
        setGlobalCurrentLocation(lat, lng);
      },
      (error) => {
        console.error("Error getting location:", error);
        setIsLocationErrorPopupOpen(true);
      },
    );

    return () => {
      navigator.geolocation.clearWatch(watchCurrent);
    };
  }, [isFirstLocationAccess, setGlobalCurrentLocation]);

  // NOTE : 지역 검색 좌표가 있을 때, 지도 영역을 검색 좌표로 잡기
  useEffect(() => {
    if (isSearch && globalSearchLatitude && globalSearchLongitude) {
      setZoom(12);

      setMapCenter({
        lat: globalSearchLatitude,
        lng: globalSearchLongitude,
      });

      setIsSearch(false);
      resetSearchLocation();
    }
  }, [
    globalSearchLatitude,
    globalSearchLongitude,
    isSearch,
    mapInstance,
    resetSearchLocation,
    setIsSearch,
  ]);

  // NOTE : 충전소 검색시, 충전소 위치로 지도 영역 잡고 충전소 상세정보 보여주기
  useEffect(() => {
    if (isSearchChargerStation && globalChargerStationList) {
      const { latitude, longitude } = globalChargerStationList[0];

      setZoom(17);

      setMapCenter({
        lat: latitude - 0.001,
        lng: longitude,
      });

      setVisibleStationDetail({
        stationData: globalChargerStationList,
        visible: true,
      });
      setSelectedStation(globalChargerStationList[0].stationId);
      setGlobalCurrentLocation(latitude - 0.001, longitude);
      setIsSearchChargerStation(false);
    }
  }, [
    globalChargerStationList,
    isSearchChargerStation,
    setGlobalCurrentLocation,
    setIsSearchChargerStation,
  ]);

  // NOTE : 충전소 검색시 해당 위치의 충전소 데이터를 가져온다.
  useEffect(() => {
    if (!isFirstLocationAccess) return;
    if (mapInstance) {
      const bounds = mapInstance.getBounds();

      const min = bounds.getMin();
      const max = bounds.getMax();

      const topLeftLat = max.y;
      const topLeftLon = min.x;
      const bottomRightLat = min.y;
      const bottomRightLon = max.x;

      setMapSearchLocation({
        topLeftLat,
        topLeftLon,
        bottomRightLat,
        bottomRightLon,
      });
    }
  }, [mapInstance, currentLocation, isFirstLocationAccess]);

  // NOTE : 충전소 상세 갔다가 다시 돌아왔을때 상세 가기전 지도 영역으로 돌아오기
  useEffect(() => {
    if (
      isDetailChargerStation &&
      globalDetailLatitude &&
      globalDetailLongitude
    ) {
      setMapCenter({
        lat: globalDetailLatitude - 0.001,
        lng: globalDetailLongitude,
      });

      setIsDetailChargerStation(false);
      setVisibleStationDetail({
        visible: true,
        stationData: globalChargerStationList,
      });
      setSelectedStation(globalChargerStationList[0].stationId);
    }
  }, [
    globalChargerStationList,
    globalDetailLatitude,
    globalDetailLongitude,
    isDetailChargerStation,
    mapInstance,
    setIsDetailChargerStation,
  ]);

  // NOTE : 지도 움직였을때, 지도 영역을 계산해서 데이터를 가져온다.
  useEffect(() => {
    if (mapInstance) {
      const listener = navermaps.Event.addListener(mapInstance, "idle", () => {
        // 지도의 현재 영역을 얻어옵니다.
        const bounds = mapInstance.getBounds();

        const min = bounds.getMin();
        const max = bounds.getMax();

        const topLeftLat = max.y;
        const topLeftLon = min.x;
        const bottomRightLat = min.y;
        const bottomRightLon = max.x;

        setMapSearchLocation({
          topLeftLat,
          topLeftLon,
          bottomRightLat,
          bottomRightLon,
        });
      });

      return () => {
        navermaps.Event.removeListener(listener);
      };
    }
  }, [mapInstance, navermaps.Event]);

  // NOTE : 추가된 마커의 애니메이션을 위한 useEffect
  useEffect(() => {
    if (addedChargerStationData.length > 0) {
      setTimeout(() => {
        setAddedChargerStationData([]);
      }, 500);
    }
  }, [addedChargerStationData.length]);

  // NOTE : 지도 줌 레벨이 16 이하일 때, 바텀 시트 닫기
  useEffect(() => {
    if (!mapInstance) return;
    const mapZoomLevel = mapInstance.getZoom();

    if (mapZoomLevel < 16) {
      if (visibleList) setVisibleList(false);

      if (visibleStationDetail.visible) {
        setVisibleStationDetail({ visible: false, stationData: [] });
        setSelectedStation("");
      }
    }
  }, [mapInstance?.getZoom()]);

  return (
    <>
      <Layout headerContentsPosition="relative" disableHeader>
        <MapDiv
          style={{
            width: "100%",
            height: `${height}px`,
          }}
        >
          <NaverMap
            ref={(map: naver.maps.Map | null) => {
              if (map) {
                setMapInstance(map);
              }
            }}
            center={mapCenter}
            disableKineticPan={false}
            defaultZoom={mapZoom}
            maxZoom={21}
            minZoom={8}
          >
            {currentLocation && (
              <Marker
                position={currentLocation}
                icon={{
                  content: CURRENT_LOCATION_MARKER,
                  size: new navermaps.Size(50, 50), // 아이콘 크기
                  anchor: new navermaps.Point(25, 25), // 아이콘 중심점
                }}
              />
            )}

            {mapInstance && mapInstance.getZoom() >= 16
              ? chargerStationData?.map((station, index) => {
                  return markerRender(station, index);
                })
              : markerCluster().map((cluster) => {
                  return (
                    cluster.properties?.cluster && (
                      <CustomCluster
                        key={cluster.id}
                        clusterData={cluster}
                        onClick={() => {
                          mapInstance?.zoomBy(
                            1,
                            {
                              lat: cluster?.geometry.coordinates[1],
                              lng: cluster?.geometry.coordinates[0],
                            },
                            true,
                          );
                        }}
                      />
                    )
                  );
                })}
          </NaverMap>
        </MapDiv>

        <SearchHeaderContainer>
          {/* 충전소 찾기 버튼 */}
          <SearchChargerArea onClick={handleSearchChargerButtonClick}>
            <SearchChargerButton>
              <Charger />
              <span>충전소 찾기</span>
            </SearchChargerButton>
          </SearchChargerArea>
        </SearchHeaderContainer>

        <LocationButtonContainer>
          {/* 새로고침 버튼 */}
          <IconButton icon={<Refresh />} onClick={handleRefreshButtonClick} />
          {/* 현재 위치 버튼 */}
          <IconButton
            icon={<CurrentLocation />}
            onClick={handleCurrentLocationButtonClick}
          />
        </LocationButtonContainer>

        {/* 목록 보기 버튼 */}
        {!visibleList && mapInstance && mapInstance?.getZoom() > 15 && (
          <ChargerListButton onClick={handleChargerListButtonClick}>
            <List />
            <span>목록보기</span>
          </ChargerListButton>
        )}

        {visibleList && (
          <AnimationBottomSheet
            isOpened={visibleList}
            setIsOpen={setVisibleList}
            height={
              chargerStationData?.length && chargerStationData?.length > 0
                ? 30 + chargerStationData?.length * 215
                : height
            }
            chargerListLength={chargerStationData?.length || 0}
          >
            {chargerStationData && chargerStationData?.length > 0 ? (
              <ChargerCardContainer>
                {chargerStationData?.map(
                  ({ stations, distance, latitude, longitude }, idx) => {
                    return stations.map((station) => {
                      const customStationData: StationDetail = {
                        ...station,
                        distance,
                        latitude,
                        longitude,
                      };
                      return (
                        <ChargerCard
                          key={`${station.stationName}_${idx}`}
                          station={customStationData}
                          onClick={() =>
                            handleChargerCardClick(customStationData)
                          }
                        />
                      );
                    });
                  },
                )}
              </ChargerCardContainer>
            ) : (
              <EmptyPlaceholder
                title="충전소가 없어요"
                description="다른 위치로 이동해주세요"
              />
            )}
          </AnimationBottomSheet>
        )}

        {visibleStationDetail.visible && visibleStationDetail.stationData && (
          <AnimationBottomSheet
            onClose={() => {
              setVisibleStationDetail({ visible: false, stationData: [] });
              setSelectedStation("");
              setGlobalChargerStationList([]);

              if (isSearchChargerStation) {
                setIsSearchChargerStation(false);
              }
            }}
            isOpened={visibleStationDetail.visible}
            chargerListLength={visibleStationDetail.stationData.length}
            maxHeight="fit-content"
          >
            <DetailChargerCard stations={visibleStationDetail.stationData} />
          </AnimationBottomSheet>
        )}

        {isLocationErrorPopupOpen && (
          <ToastPopup
            isOpen={isLocationErrorPopupOpen}
            setOpen={setIsLocationErrorPopupOpen}
            text="설정에서 위치정보 사용을 승인후 브라우저를 새로고침해주세요."
          />
        )}
      </Layout>
    </>
  );
};

export default Map;

const SearchHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const SearchChargerArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: min-content;
  padding: 20px;
`;

const SearchChargerButton = styled.button`
  width: 108px;
  height: 36px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  background: ${COLORS.WHITE};
  ${SHADOW.DP_16};

  > span {
    ${TYPO.BODY_9};
    color: ${COLORS.GRAY5};
  }
`;

const LocationButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  min-height: 140px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ChargerListButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border-radius: 100px;
  background: ${COLORS.WHITE};
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  ${SHADOW.DP_16};

  > span {
    ${TYPO.BODY_9};
    color: ${COLORS.GRAY5};
  }
`;

const ChargerCardContainer = styled.div`
  height: auto;
  overflow: auto;

  ${ChargerCardArea} {
    border-bottom: 1px solid ${COLORS.GRAY9};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const AnimationDiv = styled(motion.div)`
  width: 100%;
  height: auto;
  max-height: 60vh;
  position: fixed;
  bottom: 0;
  background: ${COLORS.WHITE};
  overflow: auto;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); // bottom shadow
`;
