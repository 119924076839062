import { LoadingModalProps } from "@components/molecules/Modal/LoadingModal";
import { create } from "zustand";

export type ModalType = "LoadingModal" | null;

export interface GlobalModalType {
  modalType: ModalType;
  modalProps: LoadingModalProps | null;
}

interface GlobalModalStore {
  globalModal: GlobalModalType;
  setGlobalModal: (modal: GlobalModalType) => void;
  hideGlobalModal: () => void;
}

const initialData = {
  globalModal: {
    modalType: null,
    modalProps: null,
  },
};

const useGlobalModalStore = create<GlobalModalStore>((set) => ({
  ...initialData,
  setGlobalModal: (modal) => set({ globalModal: modal }),
  hideGlobalModal: () =>
    set({
      globalModal: {
        modalProps: null,
        modalType: null,
      },
    }),
}));

export default useGlobalModalStore;
